.agreementModalWrapper :global(.ant-modal-content) {
    border-radius: 5px;
}

.agreementModalWrapper :global(.ant-modal-header) {
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    padding: 20px 30px 0 30px;
}

.agreementModalWrapper :global(.ant-modal-close-x) {
    font-size: 12px;
}

.agreementModalWrapper :global(.ant-modal-body) {
    padding: 20px 30px 10px 30px;
}

.agreementModalWrapper :global(.ant-modal-body)>div {
    margin-bottom: 1rem;
}

.agreementModalWrapper :global(.ant-form-vertical .ant-form-item-label > label) {
    color: rgba(0, 0, 0, 0.85);
}

.agreementModalWrapper .actionButtons {
    margin-bottom: 1rem;
}

.agreementModalWrapper .actionButtons button:first-child {
    margin-right: 1rem;
}

.agreementModalWrapper .actionButtons :global(.ant-form-item-control-input-content) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.agreementModalWrapper .optionsContainer {
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    padding: 15px 15px 0;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.agreementModalWrapper .optionsContainer :global(.ant-checkbox-wrapper) {
    width: 100%;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    margin-left: 0;
}