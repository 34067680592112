.programDetail :global(.ant-tabs-top > .ant-tabs-nav::before) {
  border-bottom-color: transparent;
}

.programDetail :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
  font-weight: normal;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.alignProgramHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.programTitle {
  display: flex;
}

.programTitle > img {
  height: 63px;
  /* width: 112px; */
  border-radius: 4px;
  margin-right: 1rem;
  object-fit: cover;
}

.programTitle > div > p:first-child {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
}

.programTitle > div > p:nth-child(2) {
  text-transform: capitalize;
}

.programDetailsStatus {
  color: #1990ff;
  border: 1px solid #1990ff;
  background-color: #0085f91a;
  border-radius: 2px;
  padding: 0.1rem 0.5rem;
  margin-left: 0.5rem;
}

.programAccessType {
  color: rgb(157, 157, 157);
}

/* .programDetails {
  display: flex;
}
.programDetails > div {
  min-width: 0;
  flex: 1;
  min-height: calc(100vh - 320px);
}
.programDetails > div:nth-child(1) {
  margin-right: 0.5rem;
}
.programDetails > div:nth-child(2) {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.programDetails > div:nth-child(3) {
  margin-left: 0.5rem;
} */

.detailsRow {
  min-height: calc(100vh - 250px);
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* PROGRAM IMAGES */
.programImagesContent {
  border: 1px solid rgb(207, 207, 207);
  border-radius: 5px;
  padding: 1rem 0.8rem 0.8rem 0.8rem;
  margin-bottom: 24px;
}

.programImagesContent > p {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.programImagesControls {
  font-size: 1.4rem;
  justify-content: center;
  margin-top: 1rem;
  display: flex;
}

.programImagesControls :global(span.anticon):hover {
  color: #1890ff;
  transition: 0.3s;
}

.controlsGap {
  font-size: 0.8rem;
  text-align: center;
  padding-top: 2px;
  width: 30px;
  user-select: none;
  color: rgb(147, 147, 147);
}

.imageSliderWrapper {
  border-radius: 5px;
  background: white;
  box-sizing: border-box;
  overflow: auto;
  width: 100%;
}

.imageSliderWrapper > div {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 56.25%;
  background-color: #0000000a;
}

/* TRAFFIC TYPES */
.trafficTypesContainer {
  border: 1px solid rgb(207, 207, 207);
  border-radius: 5px;
  padding: 1rem 0.8rem 0.8rem 0.8rem;
  /* margin-bottom: 1rem; */
}

.trafficTypesContainer > p {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.trafficTypeItem {
  width: 100%;
  color: #4d4f5c;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
}

.trafficTypeItem > span:nth-child(1) {
  /* width: 55%; */
}

.trafficTypeItem > span:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.trafficTypeItem > span span {
  margin-left: 0.4rem;
}

.scrollDiv {
  height: 310px;
  /* padding-right: 12px; */
  overflow: scroll;
}

/* PROGRAM INFORMATION */
.programInformationContainer {
  border: 1px solid rgb(207, 207, 207);
  border-radius: 5px;
  padding: 1rem 0.8rem 0.8rem 0.8rem;
  /* margin-bottom: 1rem; */
  height: 100%;
}

.validationInformationWrapperContainer, .recommendationInformationWrapperContainer {
  border: 1px solid rgb(207, 207, 207);
  border-radius: 5px;
  padding: 1rem 0.8rem 0.8rem 0.8rem;
  margin-top: 1rem;
  height: 100%;
}

.validationInformationContainer, .recommendationInformationContainer {
  height: 100%;
}

.programInformationContainer > p, .validationInformationWrapperContainer > p, .recommendationInformationWrapperContainer > p {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.programDescriptionContent {
  padding-bottom: 1.3rem;
  font-size: 0.8rem;
}

.programDescriptionWrapper {
  overflow: hidden;
  position: relative;
}

.readMoreDesc {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row-reverse;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 0.2rem;
}

.readMoreDesc > span {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.programDescription > p:first-child,
.programInformationItem > p:first-child {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.programInformationItem > .formattedCountriesContainer {
  text-align: right;
}

.programInformationItem {
  display: flex;
  justify-content: space-between;
}

.programInformationItem > div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
}

.itemTag {
  font-size: 0.8rem;
  color: #1990ff;
  border: 1px solid #1990ffb7;
  background-color: #0085f91a;
  border-radius: 2px;
  display: inline-block;
  margin-left: 0.5rem;
  margin-bottom: 0.4rem;
  padding: 0rem 0.5rem;
}

/* LANDING PAGES */

.landingPagesWrapperContainer {
  margin-top: 1rem;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 5px;
  padding: 1rem 0.8rem 0.8rem 0.8rem;
}

.landingPagesWrapperContainer > p {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.landingPagesContainer :global(.ant-image) {
  display: none;
}

.landingPageItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 4px 13px 4px;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.landingPageItem:last-child {
  border-bottom-color: transparent;
}

.accessSectionCopyBtn {
  border-radius: 5px;
  color: rgb(88, 88, 88);
}

.landingPageItem > div:first-child {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.landingPageItem > div > img {
  height: 40px;
  width: 70px;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.landingPageItem > div > span {
  padding-left: 0.6rem;
}

/* PRICING INFORMATION */

.pricingInformationContainer, .pricingAndTargetingInformationContainer {
  border: 1px solid rgb(207, 207, 207);
  border-radius: 5px;
  padding: 1rem 0.8rem 0.8rem 0.8rem;
  /* margin-bottom: 1rem; */
}

.pricingInformationContainer :global(.ant-table), .pricingAndTargetingInformationContainer :global(.ant-table) {
  font-size: 12px;
}

/* .pricingInformationContainer>p {
  margin-left: 0.4rem;
} */

.pricingInfoDescription {
  padding-bottom: 1rem;
}

.pricingTableWrapper {
  max-width: 100%;
  margin-bottom: 1rem;
}

.countryPricingTable {
  display: flex;
  align-items: center;
}

.countryPricingTable > img {
  width: 16px;
}

.countryPricingTable > span {
  margin-left: 0.5rem;
}

.priceItem {
  white-space: nowrap;
}

.pricingInformationContainer > p, .pricingAndTargetingInformationContainer > p {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.pricingInformationContainer :global(.ant-table-tbody > tr > td), .pricingInformationContainer :global(.ant-table-tbody > tr > td),
.pricingAndTargetingInformationContainer :global(.ant-table-thead > tr > th), .pricingAndTargetingInformationContainer :global(.ant-table-thead > tr > th) {
  border-bottom: transparent;
  background-color: transparent;
  padding: 4px 4px;
}

/* .pricingInformationContainer :global(.ant-table-tbody > tr > td):first-child,
.pricingInformationContainer :global(.ant-table-thead > tr > th):first-child {
  padding-left: 0px;
} */

.programBottomActions {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 15px; */
  /* align-items: center; */
}

.programBottomActionsPending {
  justify-content: flex-end;
}

.accessMessage {
  border: 1px solid #ffa13b;
  color: #ffa13b;
  background-color: #ffaa2d1a;
  /* height: 36px; */
  /* max-height: 72px; */
  /* line-height: 36px; */
  border-radius: 5px;
  padding: 10px 14px;
  min-width: 0;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.bottomActionsBtns {
  /* min-width: 325px; */
  display: flex;
  justify-content: flex-end;
}

/* PRICING REVSHARE */
.pricingRevshareValue {
  margin-bottom: 0;
}

.pricingRevshareValue span {
  padding-right: 0.5rem;
  font-weight: 600;
}

.buttonStartCamp {
  border-radius: 5px;
  padding: 0.3rem 2.2rem 0.3rem 2.2rem;
  height: 36px;
  /* padding-left: 2.2rem;
  padding-right: 2.2rem; */
  margin-left: 1rem;
}

.otherData {
  color: #989898;
  cursor: pointer;
}

.campaignsTable :global(.ant-table-footer > ul) {
  width: 100%;
}

/* PROGRAM DETAILS TABS */
.tabDetails {
  display: none;
  margin-bottom: 15px;
}

.tabSelected {
  display: block;
}

.fromWelcomePage {
  height: 30rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (min-width: 992px) {
  .trafficTypesContainer {
    height: 100%;
  }

  .pricingInformationContainer {
    height: 100%;
  }

  .pricingAndTargetingInformationContainer {
    flex: 1 1 100px;
    /* border: 1px solid gray;
    border-radius: 5px; */
    min-height: 60%;
    height: 100%;
    overflow: scroll;
  }

  .targetingWrapper {
    overflow: scroll;
    max-height: 220px;
  }

  .landingPagesWrapperContainer {
    overflow: hidden;
    margin-top: 1rem;
    max-height: 190px;
  }

  .landingPagesContainer {
    max-height: 190px;
    overflow-y: scroll;
  }

  .validationInformationContainer {
    overflow-y: scroll;
    max-height: 160px;
    margin-top: 1rem;
  }
  .recommendationInformationContainer {
    overflow-y: scroll;
    max-height: 160px;
    margin-top: 1rem;
  }
}

@media (min-width: 750px) {
  .campaignsTable :global(.ant-table-footer > ul > li:first-child) {
    background-color: #fff;
    display: inline-block;
    position: absolute;
    left: 0px;
  }
}
