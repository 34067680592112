.pageTitle {
  font-size: 1.4rem;
  padding-bottom: 15px;
  /* border-bottom: 1px solid #ccc; */
  color: #43425d;
  margin-bottom: 0.5rem;
}

.mainTableContainer :global(.ant-table-tbody > tr > td) {
  padding: 13px;
}
.mainTableContainer :global(.ant-table-thead > tr > th) {
  padding: 13px;
}

.mainTableContainer :global(.ant-table-row:nth-child(odd)) {
  background-color: #f5f6fa;
}

.mainTableContainer :global(.ant-table tfoot > tr > td) {
  border-top: 1px solid rgb(90, 94, 146);
}

.countryBox {
  display: flex;
  align-items: center;
}
.countryBox > img {
  margin-right: 0.5rem;
}

.mainTableContainer :global(.ant-table-footer > ul) {
  width: 100%;
}

@media (min-width: 750px) {
  .mainTableContainer :global(.ant-table-footer > ul > li:first-child) {
    background-color: #fff;
    display: inline-block;
    position:absolute;
    left: 0px;
  
  }
}
