.inpageContainer {
  margin-top: 2rem;
  display: flex;
}

.formContainer {
  width: 100%;
  /* max-width: 650px; */
  color: #4d4f5c;
}

.pushContent {
  margin-bottom: 2rem;
}

.titleWidget {
  color: #4d4f5c;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding-left: 0.9rem;
}

.titleTagWidget {
  color: #4d4f5c;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.titleWidget span {
  color: #4d4f5c;
  font-size: 1.3rem;
}

.frequencyContent {
  margin-left: 1rem;
}

.frequencyBox {
  margin-top: 0.5rem;
  display: flex;
}

.frequencyBox :global(.ant-input-number) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.frequencyBox .secondFreq :global(.ant-select-selector) {
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.inputNumberDelay :global(.ant-input) {
  border-radius: 5px;
}

.inputNumberDelay :global(.ant-input-group-addon) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.formTitleMain {
  font-size: 1rem;
  color: #4d4f5c;
  font-weight: 600;
}

.generationMethodInfo {
  font-size: 0.9rem;
  color: #525563;
}

.inputLabel {
  font-size: 0.9rem;
  color: #4d4f5c;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.inputLabelDesc {
  font-size: 0.9rem;
  color: #4d4f5c;
}

.widgetBoxDesc {
  font-size: 0.8rem;
  color: #4d4f5c;
  border-left: 3px solid #4d4f5c;
}

.infoLabel {
  font-size: 0.85rem;
  color: #4d4f5c;
  /* font-weight: 500; */
}

.widgetCountInfo {
  font-size: 0.9rem;
  border-left: 5px solid rgb(220, 220, 220);
  padding-left: 0.5rem;
  margin-top: 0.4rem;
  color: rgb(100, 100, 100);
}

.middleCustomBox {
  margin-bottom: 1rem;
}

.adPlacementBox {
  padding-left: 1rem;
  margin-bottom: 1.5rem;
}

.colorBox {
  width: 35px;
  height: 35px;
  border: 2px solid rgb(226, 226, 226);
  border-radius: 8px;
}

.createContent {
  margin-left: 1rem;
  flex: 1;
}

/* :global(.ant-popover-inner-content) {
    padding: 0px 0px;
  } */

.widgetsContainer {
  flex: 6;
}

.widgetsContainer p:nth-child(2) {
  border-left: 5px solid rgb(227, 227, 227);
  padding-left: 4px;
}

.widgetsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.widgetsGridTag {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 900px;
  gap: 1rem;
}

.widgetBox:first-child {
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 2px solid rgb(226, 226, 226);
  flex: 3;
}

.widgetBox:nth-child(2) {
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 2px solid rgb(226, 226, 226);
  flex: 5;
}

.widgetBox:nth-child(3) {
  margin-right: 1rem;
  flex: 4;
}

.widgetBox img {
  width: 90%;
  margin-top: 20px;
}

.widgetTitle {
  font-weight: 600;
}

.colorPreviewRow {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.colorPreviewRow>p {
  color: #8a8a8a;
  padding-left: 1rem;
  padding-right: 0.7rem;
  /* width: 200px; */
  margin: 0;
}

.undoButton {
  font-size: 1.5rem;
  color: #8a8a8a;
  margin-left: 0.6rem;
  cursor: pointer;
}

.borderRadiusBox {
  display: flex;
}

.actionBox {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.scriptBox {
  width: 900px;
  position: relative;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 1rem;
  padding-right: 2.7rem;
  overflow: auto;
  word-wrap: break-word;
  color: #aeaeae;
  margin-bottom: 1rem;
}

.copyLinkBtn {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 0px;
}

.tutorialContainer {
  width: 900px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.tutorialContainer p:last-child {
  margin: 0;
}

.tutorialContainer .videoPreview {
  cursor: pointer;
  width: 260px;
  margin-right: 30px;
}

.tutorialContainer .videoInstructions span {
  cursor: pointer;
  color: #1990ff;
  text-decoration: underline;
}

.parRule {
  /* padding-left: 1rem; */
  margin: 26px 0;
  color: #656770;
}

.parHints {
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 15px;
  max-width: 900px;
}

.parHints :global(.ant-alert-content) p {
  margin: 0;
  color: #1890ff;
  font-weight: 400;
  /* color: #4d4f5c; */
}

.infoWidgetsP {
  margin-top: 30px;
  color: #4d4f5c;
  max-width: 900px;
}

.parHintItalic {
  font-style: italic;
}

.parHintItalic>span {
  background-color: #f8494933;
  color: #f84949;
  padding: 0.1rem 0.2rem;
  border-radius: 8px;
  margin-left: 0.5rem;
}

.buttonBoxPush>.swNotUploaded {
  color: #f84949;
}

.buttonBoxPush>.swUploaded {
  color: #008002;
}

.buttonBoxPush {
  display: flex;
  align-items: center;
}

.buttonBoxPush>p {
  color: #d1d1d1;
  padding-left: 0.8rem;
  margin: 0;
}

.radioStyle {
  display: block;
  height: 30px;
  line-height: 30px;
}

.settingsBox :global(.ant-form-item) {
  margin: 0;
}

.frequencyContent :global(.ant-form-item) {
  margin: 0;
}

.settingsBox>div {
  align-items: center;
  display: flex;
  margin-bottom: 0.7rem;
}

.settingsBox>div>p {
  margin: 0;
  font-size: 0.83rem;
  font-weight: 500;
  color: rgb(99, 99, 99);
  padding-left: 1rem;
  padding-right: 0.3rem;
}

.widgetBuilderCore {
  display: flex;
}

.widgetBuilderCore>div {
  flex: 1;
}

.widgetBuilderCore>div:first-child {
  margin-right: 0.5rem;
}

.widgetBuilderCore>div:last-child {
  margin-left: 0.5rem;
}

.adPreviewType :global(.ant-radio-group) {
  display: flex;
  justify-content: center;
}

.showHideExamples {
  color: #1990ff;
  text-decoration: underline;
  cursor: pointer;
}

/* .desktopPreviewContent {
  overflow: hidden;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
} */
.mobilePreviewContent {
  overflow: hidden;
  position: relative;
  height: 540px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.desktopPreviewContent .adPreviewScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(148, 148, 148);
  padding-top: 1rem;
  max-width: 500px;
  margin: 0 auto;
}

.mobilePreviewContent .adPreviewScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 210px;
  font-size: 0.7rem;
  color: rgb(148, 148, 148);
  padding-top: 1rem;
  height: 550px;
  margin: 0 auto;
}

.headtitle {
  color: #323232;
  font-size: 1rem;
  margin-bottom: 0.4rem;
  font-weight: bold;
}

.adPreviewContentBox {
  display: flex;
  flex-wrap: wrap;
}

.desktopPreviewContent .adPreviewContentBox>div {
  position: relative;
  flex-basis: 49%;
  margin-right: 1%;
  margin-bottom: 0.2rem;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.mobilePreviewContent .adPreviewContentBox>div {
  position: relative;
  flex-basis: 100%;
  margin-bottom: 0.2rem;
  margin-right: 0;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.desktopPreviewImg {
  width: 90%;
  object-fit: contain;
  max-width: 860px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.mobilePreviewImg {
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.widgetDesignColor {
  display: flex;
  align-items: center;
}

.widgetDesignRadius {
  width: 100%;
  display: flex;
  align-items: center;
}

.widgetDesignRow {
  align-items: center;
  display: flex;
  margin-bottom: 1.1rem;
}

.widgetDesignRow :global(.ant-input-number) {
  border: 2px solid rgb(226, 226, 226);
  border-radius: 8px;
}

.widgetDesignRow :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  color: rgb(89, 89, 89);
  border: 2px solid rgb(226, 226, 226);
  border-radius: 8px;
}

.widgetDesignRow .inputLabel {
  margin: 0;
  width: 200px;
}

.selectedOption {
  background-color: #1890ff;
  margin-right: 0.5rem;
  border-color: #1890ff;
  border-radius: 8px;
  color: #fff;
}

.unselectedOption {
  background-color: #ffffff;
  border-radius: 8px;
  margin-right: 0.5rem;
  border-color: #d1d1d1;
  color: rgb(109, 109, 109);
}

.unselectedOption:focus {
  background-color: #ffffff;
  margin-right: 0.5rem;
  border-radius: 8px;
  border-color: #d1d1d1;
  color: rgb(109, 109, 109);
}

.iconPlay {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 20px;
}

.iconPlay::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 4px;
  left: 7px;
}

.playW3 {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}

.playW3::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 6px;
  left: 9px;
}

.widget3Icon {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 33px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.widgetWrapper {
  border: 2px dashed #d1d1d1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 160px;
  cursor: pointer;
}

.widgetWrapperShake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  border: 2px dashed #f16363;
  /* When the animation is finished, start again */
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  /* 10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  } */
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  /* 30% {
    transform: translate(3px, 2px) rotate(0deg);
  } */
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  /* 50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  } */
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  /* 70% {
    transform: translate(3px, 1px) rotate(-1deg);
  } */
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  /* 90% {
    transform: translate(1px, 2px) rotate(0deg);
  } */
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.widgetWrapper>div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.widgetWrapper>div>span:first-child {
  font-size: 3rem;
}

.widgetWrapper>div>span {
  color: #c2c2c2;
}

@media (max-width: 1700px) {
  .widgetBox:first-child {
    flex: 3;
  }

  .widgetBox:nth-child(2) {
    flex: 5;
  }

  .widgetBox:nth-child(3) {
    margin-right: 1rem;
    flex: 5;
  }
}

@media (max-width: 1465px) {
  .widgetsContainer {
    flex: 3;
  }

  .widgetsGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1450px) {
  .adPreviewScreen {
    padding-top: 3rem;
    width: 70%;
    max-width: 700px;
    margin: 0 auto;
  }
}

/* @media (max-width: 900px) {
  .adPreviewBox {
    display: none;
  }
} */
@media (max-width: 800px) {
  .formContainer {
    width: 100%;
  }
}

@media (max-width: 1200px) {

  .scriptBox,
  .tutorialContainer {
    width: 100%;
  }

  .widgetsGridTag {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1090px) {
  .desktopPreviewImg {
    width: 100%;
  }

  .inpageContainer {
    flex-direction: column;
  }

  .widgetBox:first-child {
    margin-right: 0rem;
    border-right: 0px;
  }

  .adPreviewBox {
    margin-left: 0rem;
  }
}

@media (max-width: 965px) {
  .tutorialContainer {
    flex-direction: column;
  }

  .tutorialContainer .videoPreview {
    width: 260px;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .tutorialContainer .videoInstructions {
    text-align: center;
  }
}