.mainContainer > h4 {
  color: #43425d;
  font-size: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgb(227, 227, 227);
}
.paymentMethodsTitle {
  font-weight: 600;
}

.payoneerContainerReg {
  position: absolute;
  bottom: 0px;
}

.payoneerInfoMessage {
  white-space: nowrap;
  font-size: 0.65rem;
  color: rgb(124, 124, 124);
  margin: 0;
  font-style: italic;
}

.payoneerLinkBtn {
  width: 175px;
}
.payoneerRegistrationBtn {
  background: #ff4700;
  border-color: #ff4700;
  color: #fff;
  width: 175px;
}

.newPaymentMethodMain {
  position: relative;
  /* display: flex; */
  flex-direction: column;
  /* align-items: center; */
}

.methodsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1100px;
}
.paymentMethodContainer,
.paymentMethodContainerTipalti {
  width: 100%;
  min-height: 146px;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgb(227, 227, 227);
  font-size: 0.8rem;
  margin-right: 1.5rem;
  /* margin-bottom: 1.5rem; */
  padding: 0.7rem 0.7rem 0.7rem 1.3rem;
  /* width: 45%; */
  border-radius: 8px;
}

.methodCursor {
  cursor: pointer;
}
.methodCursor:hover {
  opacity: 0.8;
}

.reloadFormBtn {
  margin-left: 20px;
}

.paymentMethodContainerTipalti {
  cursor: pointer;
  flex-direction: column;
}
.paymentMethodContainerTipalti:hover {
  opacity: 0.7;
}

.paymentMethodContainer :global(.ant-spin-container) {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.paymentMethodContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.priceEditBtns {
  display: flex;
  justify-content: space-between;
}

.editPriceBtn {
  margin-left: 0.6rem;
  margin-right: 1rem;
}

.paymentMethodActions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.statusDefault {
  font-size: 0.65rem;
  font-weight: 600;
  border-radius: 3px;
  background-color: #1990ff33;
  border: 1px solid #1990ff;
  color: #1990ff;
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
}
.statusVerified {
  font-size: 0.65rem;
  font-weight: 600;
  border-radius: 3px;
  background-color: #02875b33;
  border: 1px solid #02875b;
  color: #02875b;
  padding: 0.2rem 0.5rem;
}
.statusPending {
  font-size: 0.65rem;
  font-weight: 600;
  border-radius: 3px;
  background-color: #ffc06a33;
  border: 1px solid #ffc06a;
  color: #ffc06a;
  padding: 0.2rem 0.5rem;
}

.defaultDisabled {
  font-size: 0.75rem;
  pointer-events: none;
  text-decoration: underline;
  color: rgb(208, 208, 208);
}
.defaultEnabled {
  font-size: 0.75rem;
  cursor: pointer;
  text-decoration: underline;
  color: #1990ff;
}
.defaultEnabled:hover {
  opacity: 0.7;
}
.closeBtn {
  cursor: pointer;
  font-size: 0.7rem;
  color: rgb(169, 169, 169);
}
.closeBtn:hover {
  opacity: 0.7;
}
.methodHighlight {
  text-transform: capitalize;
  font-weight: 600;
}
.methodContentBox {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
.methodContentBoxForm {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
.methodContentBoxForm > div {
  display: flex;
}

.tooltipOverlay {
  font-size: 0.7rem;
}

.minimumPayoutField {
  border-radius: 4px;
  width: 80px;
  outline: none;
  border: 1px solid rgb(191, 191, 191);
}

.paymentMethodBtn {
  margin: 1rem 0;
  border-radius: 5px;
  background-color: #1990ff;
}

.methodTypeContainer {
  display: flex;
  transition: all 0.4s;
  overflow: scroll;
  margin-bottom: 20px;
}

.tipaltiSelectContainer > p {
  margin-top: 3px;
  color: #f9bc22;
  font-weight: bold;
  text-align: center;
}

.methodTypeSelect,
.methodTypeSelectTipalti {
  cursor: pointer;
  width: 140px;
  min-width: 140px;
  height: 70px;
  border-radius: 4px;
  padding: 0.2rem 0.6rem;
  border: 1px solid #eeeeee;
  background-color: #fafafa;
  transition: all 0.2s;
  z-index: 10;
  margin-right: 0.5rem;
}
.methodTypeSelectTipalti {
  display: flex;
  justify-content: center;
  align-items: center;
}

.methodTypeSelect:hover {
  /* background-color: #c8e5ff; */
  border: 1px solid #8fc9ff;
}
.methodTypeSelect > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.methodTypeSelectTipalti > img {
  object-fit: contain;
  width: 80%;
  height: 80%;
}

.orLine {
  margin-right: 0.5rem;
  height: 80px;
  display: flex;
  align-items: center;
}

.orLine > div {
  flex: 2;
  width: 80px;
  height: 2px;
  color: #8d8d8d;
  background-color: #eeeeee;
  text-align: center;
}

.orLine > .orLineTxt {
  flex: 1;
  height: auto;
  background-color: white;
}

.highlightText {
  color: rgb(104, 104, 104);
  font-size: 0.85rem;
  font-weight: 600;
}
.dualFieldItem {
  display: flex;
}
.dualFieldItem :global(.ant-input),
:global(.ant-form-item textarea.ant-input),
.dualFieldItem
  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-radius: 4px;
}
.dualFieldItem :global(.ant-form-item-label > label),
:global(.ant-form-vertical .ant-form-item-label > label) {
  color: rgb(104, 104, 104);
  font-size: 0.85rem;
}
:global(.ant-drawer-title) {
  color: rgb(84, 84, 84);
  font-weight: 600;
  font-size: 1rem;
}
.dualFieldItem
  :global(.ant-form-item-label > label.ant-form-item-required::before) {
  color: rgb(104, 104, 104);
}

.dualFieldItem :global(.ant-form-item) {
  width: 48%;
}
.dualFieldItem :global(.ant-form-item):first-child {
  margin-right: 4%;
}

@media only screen and (max-width: 1100px) {
  .orLine > div {
    width: 50px;
  }
}

@media only screen and (max-width: 950px) {
  .orLine > div {
    width: 30px;
  }
  .methodsGrid {
    grid-template-columns: 1fr;
  }
}
