.widgetWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  overflow: hidden;
  position: relative;
}

.widgetHeader {
  height: 45px;
  background-color: #fafafa;
  border-bottom: 1px solid #d1d1d1;
  line-height: 45px;
  padding-left: 15px;
  color: rgb(100, 100, 100);
  font-weight: 600;
}

.widgetContent {
  flex: 1;
}

.widgetFooter {
  height: 45px;
  background-color: #fafafa;
  border-top: 1px solid #d1d1d1;
  line-height: 45px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
}
.getTagFooter {
  justify-content: space-between;
}

.widgetScriptDiv {
  font-size: 0.8rem;
  position: absolute;
  height: calc(100% - 45px);
  overflow-y: scroll;
  top: 0;
  width: 100%;
  left: 0;
  background-color: #fff;
  padding: 10px 20px;
  opacity: 0;
  transition: opacity 0.5s;
  color: rgb(136, 136, 136);
}

.widgetScriptVisible {
  opacity: 1;
}

.copyLinkBtn {
  border-radius: 4px;
}
