.pageTitle {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 0.5rem;
}

.pageTitle>h2 {
  color: #43425d;
  font-size: 1.4rem;
  margin: 0;
  padding-right: 1rem;
}

.mainContainer table thead th {
  background-color: #fff;
  color: #707070;
  font-size: 0.9rem;
}

.mainContainer table tbody {
  font-size: 0.85rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080 !important;
}

.mainContainer :global(.ant-table-row) {
  background-color: #fff;
}

.mainContainer :global(.ant-table-footer) {
  background-color: #fff;
}

.mainContainer :global(.ant-table-tbody > tr > td) {
  padding: 10px 12px;
}

/* FILTERS AREA */

.filtersContainer {
  background-color: #fafafa;
  padding: 0.5rem 1rem 1rem 1rem;
  margin-bottom: 0.6rem;
}

.filtersContainer h3 {
  color: #4D4F5C;
  font-weight: 600;
  margin-top: .5rem;
}

.filtersContainer :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-radius: 5px;
}

.filtersContainer :global(.ant-picker) {
  border-radius: 5px;
}

.spanMarginBottom {
  display: block;
  margin-bottom: .3rem;
  color: rgb(95, 95, 95);
}

.selectField {
  width: 100%;
  margin-right: 1rem;
}

.applyFiltersBtn {
  margin-top: 27px;
  background-color: #1990FF;
  border-color: #1990FF;
}

/* Grid style */

.samplesWrapper {
  padding: 1rem 0;
}

.sampleCard {
  border-radius: 10px;
  box-shadow: 0px 0px 15px #03397A1A;
  position: relative;
  height: 100%;
}

.sampleCard .detailsWrapper {
  padding: 20px;
  height: inherit;
  /* min-height: 440px; */
}

.sampleCard .cardContent {
  height: calc(100% - 192px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sampleCard .sampleImage {
  margin-bottom: .8rem;
  display: block;
  border-radius: 5px;
  box-shadow: 0px 0px 8px #B9B1A980;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 180px;
  position: relative;
}

.sampleCard .imageButtons {
  display: inline-block;
  position: absolute;
  bottom: 14px;
  right: 14px;
}

.sampleCard .imageButtons button:first-child {
  margin-right: 8px;
}

.sampleCard .imageButtons :global(.ant-btn) {
  background: #0000005A;
  color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 3px #00000040;
}

.sampleCard .rowContainer {
  margin-bottom: .7rem;
}

.sampleCard .rowContainer:last-child {
  margin-bottom: 0;
}

.sampleCard .rowContainer :global(.ant-btn-icon-only) {
  height: 25px;
  width: 25px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline;
  vertical-align: 2px;
}

.sampleCard .rowContainer h2>span,
.sampleCard .rowContainer h3>span {
  margin-right: .6rem;
}

.sampleCard .rowContainer :global(.ant-btn-icon-only > *) {
  font-size: 12px;
  height: 12px;
}

.sampleCard h2 {
  font-size: 20px;
  color: #4D4F5C;
  margin-bottom: 0;
  margin-right: .6rem;
}

.sampleCard h3 {
  font-size: 16px;
  color: #989898;
  margin-bottom: 0;
  font-weight: normal;
}

.sampleCard h4 {
  font-size: 16px;
  font-weight: 600;
  color: #4D4F5C;
  margin-bottom: .3rem;
}

.sampleCard .sampleId {
  color: #4D4F5C;
  font-weight: 600;
  font-size: .9rem;
  margin-bottom: 1.1rem;
}

.sampleCard .cardSection {
  margin-bottom: .5rem;
  color: #4D4F5C;
}

.otherData {
  color: #989898;
}

.spanStyle {
  border-radius: 2px;
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  display: inline-block;
  text-transform: capitalize;
  border: 1px solid #1990FF;
  background-color: #1990FF1A;
  color: #1990FF;
  font-weight: 400;
}

.devicesContainer>span:first-child {
  margin-right: 1rem;
}

.devicesContainer :global(.anticon) {
  margin-right: .5rem;
}

@media (max-width: 991px) {

  .inputTextSearch,
  .selectStatuses,
  .rangeDatePicker,
  .selectFormats {
    width: 100%;
    max-width: none;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .applyFiltersBtn {
    background-color: #3d8af8;
    border-color: #3d8af8;
    max-width: 400px;
    margin-top: .8rem;
  }

  .mainContainer :global(.ant-radio-group-large .ant-radio-button-wrapper) {
    display: none;
  }

  .extActionGroup {
    flex-direction: column;
  }

  .titleContent {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 767px) {
  .filtersContainer :global(.ant-col) {
    margin-bottom: .5rem;
  }
}