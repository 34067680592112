.examplesContainer {
  padding: 0 0 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.examplesContainer > div {
  display: flex;
  justify-content: baseline;
  flex-direction: column;
}
.examplesContainer > div:first-child {
  margin-right: 1.5rem;
}
.alignCenter {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.alignCenter p {
  margin: 0 0 0 0.7rem;
}
.correct {
  color: #58a54f;
  font-weight: 700;
}
.incorrect {
  color: #d85c5c;
  font-weight: 700;
}
.contextualExampleText {
  color: #1990FF;
  font-weight: 700;
}
@media (max-width: 1200px) {
  .examplesContainer {
    flex-direction: column;
  }
  .examplesContainer > div:first-child {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}