.backBtn {
  border-radius: 4px;
}

.missingDateTitle {
  font-weight: 500;
  margin-bottom: 8px;
}

.missingDateContent {
  margin-bottom: 20px;
}
