.TableTitle {
  display: flex;
  font-size: 0.75rem;
  font-weight: 500;
  color: rgb(91, 91, 91);
}

.TableTitle > .arrows {
  float: left;
  margin-left: 8px;
  font-size: 0.7em;
  margin-top: -3px;
  color: #bbbbbb;
  cursor: pointer;
}

.TableTitle > .arrows > :global(.active-arrow span) {
  color: #1890ff;
}

.TableTitle > .arrows > :global(.anticon-caret-up) {
  display: block;
}

.TableTitle > .arrows > :global(.anticon-caret-down) {
  display: block;
}
.TableTitle > .title {
  user-select: none;
}
