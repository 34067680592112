.mainContainer {
  max-width: 50%;
}

.mainContainer > h4 {
  color: #4d4f5c;
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 0.375rem;
}

.mainContainer .countriesList {
  margin-top: 1.25rem;
}

.mainContainer :global(.ant-badge-status-text) {
  margin-left: 15px;
}

.productExampleTitle {
  display: flex;
  justify-content: space-between;
}

.productExampleTitle .productExampleTitleLeft {
  text-align: left;
  /* font: normal normal normal 16px/13px Source Sans Pro; */
  font-size: 0.9rem;
  /* letter-spacing: 0px; */
  color: #4d4f5c;
}

.productExampleTitle .productExampleTitleRight {
  display: flex;
}

.productExampleTitle .productExampleTitleRight > span {
  color: #1990ff;
  font-size: 0.75rem;
  line-height: 0.813rem;
  display: flex;
  align-items: center;
}

.productExampleTitle .productExampleTitleRight > div {
  margin-left: 0.28rem;
  display: flex;
  align-items: center;
}

.exampleImage {
  width: 100%;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px;
}

.titleDashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.dashBottomContent {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

.statsDivider {
  margin-top: 1rem;
  height: 1.5rem;
  width: calc(100% + 48px);
  transform: translateX(-24px);
  background-color: #f0f2f5;
}

/* CONTACT INFO */
.contactInfoSpin {
  grid-column: span 2;
}

.statsTitle {
  color: #4d4f5c;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 1.375rem;
  margin-bottom: 0.625rem;
}

.statsDescription {
  margin-top: 10px;
  color: #4d4f5c;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 1rem;
  /* line-height: 1.375rem; */
}

.readingExampleElement {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.readingExampleElementImage {
  flex: 0 1 auto;
  width: 100%;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px;
}

.statsTitleBlog {
  display: flex;
  flex-flow: column;
  max-height: 100%;
  flex: 1 1 auto;
}

.statsTitleBlogTitle {
  margin-top: 20px;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #4d4f5c;
  flex: 0 1 auto;
}

.statsTitleBlogLinkWrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.statsInfo {
  color: #8a8a8a;
}

.statsTitle > span {
  color: #f9ae3b;
}

.countryName {
  color: #4d4f5c;
  text-align: left;
  font-size: 1rem;
  line-height: 1.875rem;
}

@media (max-width: 2000px) {
  .mainContainer {
    max-width: 70%;
  }
}

@media (max-width: 1600px) {
  .mainContainer {
    max-width: 80%;
  }
}

@media (max-width: 1300px) {
  .mainContainer {
    max-width: 95%;
  }
}
@media (max-width: 1000px) {
  .mainContainer {
    max-width: 100%;
  }
}
