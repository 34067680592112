.zoneModalWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.modalContent {
  flex-basis: 220px;
  position: relative;
  margin: 0.6rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 17px;
  border: 1px solid #dadada;
  border-radius: 7px;
  /* background: #f5f9fa; */
  min-height: 270px;
  transition: all 0.2s;
}

:global(.ant-modal) {
  width: 1080px;
  /* width: 1330px; */
}

.modalContent:hover {
  background: #ffffff;
  /* transform: scale(1.02); */
}

.modalContent h1 {
  color: #4D4F5C;
  font-size: 1.1rem;
  width: 100%;
  margin: 0;
  font-weight: 600;
  text-align: center;
}
.modalContent .textWrapper {
  margin-bottom: 32px;
}
.modalContent .textWrapper h3 {
  color: rgb(32, 105, 179);
  font-size: 0.9rem;
  font-weight: 700;
  margin: 6px 0;
  text-align: center;
}

.modalContent .mainTextWrapper {
  display: flex;
}

.modalContent .textWrapper span {
  color: orange;
  margin-right: 8px;
  font-size: 6px;
  line-height: 2.9;
}

.modalContent .mainText {
  font-size: 0.8rem;
  color: #a0a0a0;
  margin-bottom: 16px;
}

.modalContentButton {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 1rem 0;
  bottom: 0;
  width: calc(100% - 34px);
  border-radius: 5px;
}

.modalContentButton:disabled {
  background-color: #d1d1d1;
  color: #fff;
}
.modalContentButton:disabled:hover {
  background-color: #d1d1d1;
  color: #fff;
}

.titleZoneModal {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0;
}

.starImage {
  position: absolute;
  top: -36px;
  right: -36px;
}
