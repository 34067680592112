.statisticsLineHeader {
  padding: 0.3rem 0;
  font-size: 1.2rem;
  color: #4d4f5c;
  display: flex;
  justify-content: space-between;
}
.lineChartContainer {
  box-shadow: 0px 2px 6px #0000000d;
  margin-bottom: 1.5rem;
}

.dateRangeBox {
  display: flex;
  justify-content: space-between;
}

.dateRangeBox > div {
  display: flex;
  align-items: center;
}
.dateRangeBox :global(.ant-radio-button-wrapper) {
  color: #626c8a;
}

.dateRangeBox :global(.ant-picker-input > input) {
  color: #626c8a;
}

/* .dateRangeInput {
  box-shadow: 0px 2px 6px #00000012;
  border: 0px;
} */
