.feedbackButton {
  display: flex;
  position: fixed;
  bottom: 100px;
  right: -45px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #00356f;
  transform: rotate(-90deg);
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s;
}

.feedbackButton > span {
  font-size: 1.125rem;
  color: white;
  margin: 0px;
  padding-bottom: 30px;
}

.feedbackButton:hover {
  opacity: 0.9;
  transform: translateX(-10px) rotate(-90deg);
}

.feedbackModal :global(.ant-radio-button-wrapper) {
  margin-right: 0.5rem;
  width: 4rem;
  text-align: center;
}

.feedbackRating > div {
  width: 50px;
  height: 50px;
}
