.widgetsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  max-height: 65vh;
  overflow: scroll;
}

.bottomActions {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.bottomActions :global(.ant-btn) {
  margin-left: 20px;
}

@media (max-width: 1233px) {
  .widgetsGrid {
    grid-template-columns: 1fr;
  }
}
