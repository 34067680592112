.greenColorFlag {
  padding: 4px 6px;
  background-color: #e4f3ee;
  border-radius: 4px;
}
.redColorFlag {
  padding: 4px 6px;
  background-color: #fcebeb;
  border-radius: 4px;
}
.yellowColorFlag {
  padding: 4px 6px;
  background-color: #fdfcea;
  border-radius: 4px;
}
