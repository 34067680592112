.analyticsDiv {
  display: flex;
  flex-direction: column;
}

.analyticsDiv > div {
  padding-left: 10px;
  border-left: 3px solid rgb(217, 217, 217);
}
.analyticsDiv > p {
  padding-top: 10px;
  padding-bottom: 0px;
  color: rgb(177, 177, 177);
  font-weight: 600;
}

.buttonUpload {
  color: rgb(177, 177, 177);
  min-height: 50px;
  line-height: 50px;
}

.actionsBtns {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-end;
}

.imagePreview {
  max-height: 100px;
}
.modalTitle {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
