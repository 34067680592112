.titleContent {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  color: #43425d;
}
.titleContent > h3 {
  padding-right: 2rem;
  font-weight: normal;
  margin: 0;
}

.advancedFilters :global(.Select-menu-outer) {
  z-index: 10;
}

.filtersContainer {
  background-color: #fafafa;
  padding: 0.5rem 1rem 1rem 1rem;
}

.filtersContainer
  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-radius: 5px;
}

.filtersContainer :global(.ant-picker) {
  border-radius: 5px;
}
.dateRadioSelect {
  font-size: 0.9rem;
}
.filtersContainer :global(.ant-radio-group-large .ant-radio-button-wrapper) {
  color: #2f6fb6;
  font-size: inherit;
  background-color: transparent;
}
.filtersContainer :global(.ant-radio-group-large .ant-radio-button-wrapper-checked) {
  font-weight: 550;
  border: 1px solid !important;
  border-color: rgba(47, 111, 182, 0.4) !important;
  border-radius: 6px;
  background-color: rgba(47, 111, 182, 0.03);
  line-height: 28px;
  height: 30px;
}
/* .filtersContainer :global(.ant-radio-group-large .ant-radio-button-wrapper) {
  color: #3d8bf88f;
  font-size: inherit;
  background-color: transparent;
}
.filtersContainer
  :global(.ant-radio-group-large .ant-radio-button-wrapper-checked) {
  color: #3d8af8;
} */
.filtersContainer :global(.ant-radio-group-large .ant-radio-button-wrapper) {
  border: none;
}
.filtersContainer
  :global(.ant-radio-group-large .ant-radio-button-wrapper::before) {
  display: none;
}
.filtersContainer :global(.Select-control) {
  border-radius: 2px;
}
.filtersContainer :global(.Select-arrow) {
  border-color: #d9d9d9 transparent transparent;
}

.reloadBtn {
  color: #a3a6b4;
  margin-left: 1rem;
  border: 1px solid #bcbec6;
  border-radius: 0;
}

.columnsBtn {
  color: #464646;
  border: 1px solid rgb(196, 196, 196);
  margin-left: 0;
  border-radius: 3px;
  margin-right: 1rem;
}

.columnsBtn:last-child {
  margin-right: 0px;
}

.dateRadioSelect {
  margin-bottom: 0.5rem;
}
/* .filtersContainerBottom {
  display: flex;
} */
.rangeDatePicker {
  margin-right: 1rem;
}
.inputTextSearch {
  max-width: 400px;
  width: 50%;
  min-width: 150px;
  margin-right: 1rem;
}

.hideShowLinkBtn {
  color: #3d8af8;
  padding: 0.5rem 0 0 0;
  font-size: 0.8rem;
}

.selectStatuses,
.selectFormats {
  min-width: 120px;
  margin-right: 1rem;
}

.applyFiltersBtn {
  background-color: #3d8af8;
  border-color: #3d8af8;
}

.topActionBtns {
  padding: 0.8rem 0;
}
.columnGroupContainer {
  width: 500px;
}
.columnGroupContainer :global(.ant-checkbox-inner) {
  width: 20px;
  height: 20px;
}

.topActionButton {
  margin-right: 1rem;
}

.extActionGroup {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem;
}

.exportCsvBtn {
  border-color: #3d8af8;
  color: #3d8af8;
}

.bottomActions {
  margin-top: 2rem;
  display: flex;
}
.bottomActions .rightActionBtns > * {
  margin-left: 0;
}

.rightActionBtns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottomActions :global(.ant-radio-button.ant-radio-button-checked) {
  background-color: #3d8af8;
  border-color: #3d8af8;
}

/* virtualized select inputs */

/* .advancedFilters :global(.Select) {
  width: 200px;
  height: 32px;
  margin-right: 1rem;
  border-radius: 0;
} */
.advancedFilters :global(.Select-control) {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  height: 31px;
  z-index: 10;
  overflow: auto;
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
}
.advancedFilters :global(.Select-value-label) {
  max-width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.advancedFilters :global(.Select-input) {
  height: 30px;
}
.advancedFilters :global(.Select-placeholder) {
  line-height: 31px;
}
.advancedFilters :global(.Select-value) {
  width: calc(100% - 10px);
  margin-right: 3px;
  white-space: nowrap;
  overflow: hidden;
}

.advancedFilters {
  color: rgb(95, 95, 95);
}

.advancedFilters :global(.has-value .Select-control > *:last-child) {
  display: inline-block;
  margin-left: 0rem;
}

.advancedFilters :global(.Select--multi .Select-clear-zone) {
  display: inline-block;
  margin-left: 5.5rem;
}

.isArchivedCheckbox {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 10px;
  padding-bottom: 5px;
}

@media (max-width: 1000px) {
  .bottomActions {
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
  }
  .inputTextSearch,
  .selectStatuses,
  .rangeDatePicker,
  .selectFormats {
    width: 100%;
    max-width: none;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .rightActionBtns {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }

  /* .filtersContainer :global(.ant-radio-group-large .ant-radio-button-wrapper) {
    display: none;
  } */

  .bottomActions :global(.ant-radio-group-solid) {
    display: none;
  }

  .bottomActions .rightActionBtns > * {
    margin-left: 0rem;
    margin-top: 0.5rem;
    width: 100%;
  }
}
