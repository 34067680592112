.mainWrapper {
  position: absolute;
  width: 85%;
  max-width: 450px;
}
.mainWrapper > div:nth-child(2){
  margin-top: 1rem;
}
/* OLD SCHOOL */
.oldSchool {
  display: flex;
  padding: 1rem;
  box-shadow: 0px 5px 10px #00000014;
}
.oldSchool > div {
  padding-left: 1rem;
}
.oldSchool > div > p {
  margin: 0;
}
.oldSchool > div > p:first-child {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.oldSchool > img {
  height: 80px;
  width: 80px;
  object-fit: cover;
}

.closeBtnOldSchool {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 0.7rem;
  font-weight: 600;
  color: #fff;
  background-color: rgb(223, 73, 73);
  text-align: center;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  width: 20px;
  opacity: 0;
  cursor: pointer;
}

.oldSchool:hover .closeBtnOldSchool {
  opacity: 1;
  transition: 0.3s;
}

/* SHARP */
.sharp {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0px 5px 10px #00000014;
}
.sharpTop {
  display: flex;
  margin-bottom: 1rem;
}
.sharpTop > div {
  padding-left: 1rem;
}
.sharpTop > div > p {
  margin: 0;
}
.sharpTop > div > p:first-child {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.sharpTop > img {
  height: 80px;
  width: 80px;
  object-fit: cover;
}
.sharpBottom {
  display: flex;
  justify-content: space-between;
}
.sharpBottom > p {
  text-align: center;
  margin: 0;
  width: 48%;
  border: none;
  border-radius: 0;
  padding: 0.4rem;
  background-color: #ffffff2c;
}

/* COMPACT */
.compact {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0px 5px 10px #00000014;
}
.compactTop {
  display: flex;
  margin-bottom: 0.5rem;
}
.compactTop > div {
  padding-left: 1rem;
}
.compactTop > div > p {
  margin: 0;
}
.compactTop > div > p:first-child {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.compactTop > img {
  height: 80px;
  width: 80px;
  object-fit: cover;
}
.compactBottom {
  margin: 0;
}

/* CHATTY */
.chatty {
  display: flex;
  align-items: center;
}

.chatty > img {
  height: 80px;
  width: 80px;
  border-radius: 100px;
  object-fit: cover;
}

.chattyBody {
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.chattyContent {
  width: 100%;
  padding: 0.5rem 1rem;
  z-index: 1001;
  box-shadow: -5px 3px 5px #0000001a;
}
.chattyContent > p {
  margin: 0;
}
.chattyContent > p:first-child {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.chattyCube {
  height: 20px;
  width: 20px;
  transform-origin: 0% 50%;
  transform: rotate(45deg) translateX(-10px);
  z-index: 1000;
  box-shadow: 2px 3px 10px #0000001a;
}

.closeBtnChatty {
  position: absolute;
  top: -3px;
  right: -3px;
  font-size: 0.7rem;
  font-weight: 600;
  color: #fff;
  background-color: rgb(223, 73, 73);
  text-align: center;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  width: 20px;
  opacity: 0;
  cursor: pointer;
}

.chatty:hover .closeBtnChatty {
  opacity: 1;
  transition: 0.3s;
}
