.accessModalWrapper :global(.ant-modal-content) {
  border-radius: 5px;
}

.accessModalWrapper :global(.ant-modal-header) {
  border-radius: 5px 5px 0 0;
}

.accessModalContainer {
  display: flex;
}

.accessModalSidemap {
  width: 30%;
}

.accessModalContent {
  max-height: 70vh;
  overflow: scroll;
  width: 70%;
  padding-left: 1rem;
  padding-right: 5px;
}

.accessModalContent h5 {
  font-size: 1rem;
  color: rgb(73, 73, 73);
  font-weight: 600;
}

.accessModalContentDescription {
  font-weight: normal;
  font-size: 0.85rem;
  padding-left: 0.5rem;
}

.accessModalSidemap p {
  color: #989898;
}

.accessModalContent > div > p {
  font-weight: 500;
}

.accessModalSidemap > div {
  display: flex;
  flex-direction: column;
}

.accessModalSidemap > div > span {
  margin-bottom: 0.5rem;
}

.accessModalTitle {
  font-size: 1.1rem;
  font-weight: 500;
}

.accessModalContentBox {
  padding-left: 0.5rem;
}

.accessModalContentBox .accessModalCountryItem {
  margin-bottom: 0.3rem;
  color: #4d4f5c;
}

.trafficTypeItem {
  color: #4d4f5c;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
}

.formVariationsHeader,
.inputVariant {
  display: flex;
  align-items: center;
}

.inputVariantTop {
  margin-right: 50px;
}

.formVariationsHeader > p,
.inputVariant :global(.ant-form-item) {
  flex: 1;
}

.inputVariant :global(.ant-form-item) {
  margin-right: 1rem;
}

.inputVariant :global(.ant-form-item-explain) {
  margin-left: 10px;
}

.productLinkInput {
  margin-bottom: 10px;
}

.accessSectionTitle {
  font-size: 1rem;
  margin-bottom: 0px;
  font-weight: 600;
  color: #4d4f5c;
}

.lpInfo {
  font-size: 0.8rem;
  color: #4d4f5c;
}
.lpInfo > span {
  font-weight: 600;
  color: #ffa543;
}

.accessMinorTitle {
  color: #4d4f5c;
  margin-top: 10px;
  margin-bottom: 0.3rem;
}

.inputFieldParameter {
  margin-right: 8px;
}

.inputFieldParameter,
.inputFieldSubid {
  flex: 1;
  border-radius: 0;
}

.inputVariant:first-child .inputFieldParameter,
.inputVariant:first-child .inputFieldSubid,
.inputVariant:first-child .deleteSubidBtn {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.inputVariant:not(:first-child) .inputFieldParameter,
.inputVariant:not(:first-child) .inputFieldSubid,
.inputVariant:not(:first-child) .deleteSubidBtn {
  border-top: 0;
}

.inputVariant:last-child .inputFieldParameter,
.inputVariant:last-child .inputFieldSubid,
.inputVariant:last-child .deleteSubidBtn {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.inputFieldSubid {
  margin-left: 8px;
}

.addSubidContainer {
  padding-top: 8px;
  padding-right: 48px;
  display: flex;
  justify-content: flex-end;
}

.buttonRad {
  border-radius: 5px;
}
.addParamBtn {
  border-radius: 5px;
  color: #1990ff;
  border-color: #1990ff;
  margin-bottom: 20px;
}

.modalFooterContainer {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

/* .spinningModal {
  min-height: 800px;
} */

.deleteSubidBtn {
  margin-left: 16px;
  /* border-radius: 5px; */
}

.accessSectionCopyBtn,
.accessSectionCopyBtnLigth {
  border-radius: 0;
  width: 35px;
  min-width: 35px;
  border-radius: 5px;
  margin-left: 20px;
}

.accessSectionInput {
  border-radius: 0;
  border-radius: 5px;
}

.accessSectionInputLight {
  border-radius: 0;
  border-radius: 5px;
}

.accessSectionContainer {
  display: flex;
  margin-bottom: 1rem;
}
.accessSectionContainer .titleSection {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.titleError {
  color: rgba(211, 50, 50, 0.871);
}

.accessSectionContainer :global(.ant-input[disabled]) {
  color: rgba(0, 0, 0, 0.41);
  background-color: #f6f6f6;
  cursor: not-allowed;
  opacity: 1;
}

.postbackSectionContainer {
  display: flex;
  margin-bottom: 0.4rem;
}

.bottomFormLayout {
  display: flex;
  margin-bottom: 1rem;
}

.bottomFormLayout > div {
  flex: 1;
}

.bottomFormLayout > div:first-child {
  margin-right: 8px;
}

.bottomFormLayout > div:last-child {
  margin-left: 8px;
}

.modalTitle {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #4d4f5c;
}

/* Start DDL style */

.modalForm .columnHeading {
  color: #4d4f5c;
  font-size: 1.025rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.modalForm .tableHead span {
  font-weight: 400;
  margin-right: 0.2rem;
}

.modalForm .tableHead :global(.anticon) {
  cursor: pointer;
  color: #1990ff;
}

.modalForm p {
  margin-bottom: 0.5rem;
}

.modalForm :global(.ant-form-item) {
  margin-bottom: 8px;
}

.modalForm :global(.ant-table-tbody .ant-input-number),
.modalForm :global(.ant-table-tbody input) {
  max-width: 70px;
  /* text-align: center; */
}

.modalForm :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
  background: transparent;
}

.modalForm :global(.ant-table-tbody > tr > td),
.modalForm :global(.ant-table-thead > tr > th) {
  background: transparent;
  border-bottom: none;
  padding: 10px 16px;
}

.modalForm :global(.ant-table .ant-table-selection-column) {
  padding-right: 8px;
  padding-left: 8px;
}

.modalForm :global(.ant-collapse-content > .ant-collapse-content-box) {
  padding: 0px;
}

/* .landingPagesTable {
  padding: 3px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 4px 0px inset;
} */

.landingPagesTable ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.landingPagesTable ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.modalForm
  :global(
    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow
  ) {
  left: 0;
}

.modalForm :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
  padding-left: 20px;
}

.modalForm
  :global(
    .ant-collapse-ghost
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box
  ) {
  padding-top: 0;
  padding-bottom: 0;
}

.startCampaignContainer {
  border-top: 1px solid rgb(225, 225, 225);
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.regularLpPreview {
  height: 40px;
  width: 70px;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.postbackMacros {
  color: rgb(93, 93, 93);
}

.postbackMacrosTitle {
  margin-bottom: 5px;
}

.postbackMacrosTitle > span {
  color: rgb(174, 174, 174);
}

.postbackMacrosWarning {
  font-size: 0.8rem;
}

.postbackMacrosWarning > span {
  font-style: italic;
  padding-left: 0.2rem;
  font-weight: 600;
}

.macroItem {
  cursor: pointer;
  display: inline-block;
  color: rgb(106, 106, 106);
  border: 1px solid rgb(215, 215, 215);
  padding: 0.1rem 0.6rem;
  margin-right: 0.4rem;
  margin-bottom: 0.5rem;
  border-radius: 40px;
  transition: all 0.3s;
  font-size: 0.85rem;
}

.macroItemActive {
  color: #ffaa2d;
  border-color: #ffaa2d;
}

.macroItem:hover {
  opacity: 0.7;
}

.bottomSpace {
  min-height: 300px;
}

.buttonUpload {
  color: rgb(177, 177, 177);
  min-height: 50px;
  line-height: 50px;
}

.deleteIcon {
  display: flex;
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;
  background-color: rgba(211, 50, 50, 0.871);
  border-radius: 50%;
  font-size: 1rem;
  padding: 0.2rem;
  -webkit-transform: translateX(22px) translateY(-44px);
  transform: translateX(22px) translateY(-44px);
  transition: all 0.2s;
}

.imageDelete {
  align-items: center;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 15px;
  top: 0;
  transition: 0.2s;
  /* width: 100%; */
}
.imagePreview {
  border-radius: 5px;
  height: 100px;
  object-fit: contain;
  width: auto;
}

.carouselWrapper {
  height: 130px;
  width: 100%;
  display: flex;
  overflow: scroll;
  border: 1px solid #d9d9d9;
  padding: 10px;
  border-radius: 5px;
}

.imageWrapper {
  border-radius: 5px;
  height: 100px;
  margin: 4px;
  position: relative;
}

.radioGrupSetup {
  margin-top: 10px;
  margin-bottom: 10px;
}

.btnGroupModal {
  display: flex;
}
