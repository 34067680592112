.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageTitle {
  font-size: 1.4rem;
  padding-bottom: 15px;
  /* border-bottom: 1px solid #ccc; */
  color: #43425d;
  margin-bottom: 0.5rem;
}

.missingDatesBtn {
  border-color: #f9ae3b;
  color: #f9ae3b;
}

.mainTableContainer :global(.ant-table-tbody > tr > td) {
  padding: 13px;
  text-align: center;
}
.mainTableContainer :global(.ant-table-tbody > tr > td:first-child) {
  padding: 13px;
  text-align: center;
}
.mainTableContainer :global(.ant-table-summary > tr > td) {
  text-align: center;
}

.mainTableContainer :global(.ant-table-summary > tr > td:first-child) {
  text-align: center;
}

.mainTableContainer :global(.ant-table-thead > tr > th) {
  padding: 13px;
}

.mainTableContainer :global(.ant-table-cell-fix-left) {
  background-color: #fafafa;
}

/* .mainTableContainer :global(.ant-table-thead > tr > th > div) {
  justify-content: flex-start;
} */

/* .mainTableContainer :global(.ant-table-row:nth-child(odd)) {
  background-color: #f5f6fa;
} */

.mainTableContainer :global(.ant-table-row) {
  height: 60px;
  color: rgb(76, 76, 76);
}

.mainTableContainer :global(.ant-table tfoot > tr > td) {
  border-top: 1px solid rgb(90, 94, 146);
}

.mainTableContainer :global(.ant-table-footer > ul) {
  width: 100%;
}

.missingDatesBox {
  margin-top: 1rem;
  text-align: center;
}
.missingDatesBox > p {
  color: rgb(49, 78, 109);
  margin: 0;
  padding: 0.3rem;
  border-bottom: 1px solid rgb(237, 237, 237);
}
.missingDatesBox > p:nth-child(odd) {
  background-color: #f5f6fa;
}

.countryBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.countryBox > img {
  margin-right: 0.5rem;
}

.channelNameTitle {
  max-width: 400px;
  overflow: hidden;

  text-overflow: ellipsis;
}

@media (min-width: 750px) {
  .mainTableContainer :global(.ant-table-footer > ul > li:first-child) {
    background-color: #fff;
    display: inline-block;
    position: absolute;
    left: 0px;
  }
}

.greenText {
  color: #00a86b;
  font-weight: 600;
}
