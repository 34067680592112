.widgetContentWrapper {
  border: 3px solid #cccccc;
  border-radius: 10px;
  min-height: 165px;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.widgetSelected {
  border: 3px solid #1990ff;
  border-radius: 10px;
  opacity: 1;
}

.widgetWrapper {
  cursor: pointer;
}
.widgetWrapper > div:first-child {
  padding-bottom: 5px;
  font-size: 1rem;
  color: #4d4f5c;
}
