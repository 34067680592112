.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

:global(.ant-tooltip-content) {
  font-size: 0.8rem;
}
:global(.ant-tooltip-inner) {
  border-radius: 5px;
  /* padding: 2px 8px; */
}
