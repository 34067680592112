.headingActions {
  display: flex;
  margin-bottom: 30px;
}

.pageTitle {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  /* border-bottom: 1px solid #ccc; */
  margin-bottom: 0.5rem;
}
.pageTitle > h2 {
  color: #43425d;
  font-size: 1.4rem;
  margin: 0;
  padding-right: 1rem;
}

.antTableContent {
  margin-top: 2%;
}

.newCampaignBtn {
  background-color: #3d8af8;
}

.headingActions > div {
  margin-right: 20px;
}

.columnGroupContainer {
  width: 500px;
}
.columnGroupContainer :global(.ant-checkbox-inner) {
  width: 20px;
  height: 20px;
}

.searchInput {
  width: 250px;
}

.campaignsTable table thead th {
  background-color: #fff;
  color: #707070;
  font-size: 0.9rem;
}

.campaignsTable table tbody {
  font-size: 0.85rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080 !important;
}

.campaignsTable :global(.ant-table-row) {
  background-color: #fff;
}

.campaignsTable :global(.ant-table-footer) {
  background-color: #fff;
}

.campaignsTable :global(.ant-table-footer > ul) {
  width: 100%;
}

.statusColumn,
.status,
.campaignNameWrapper,
.campaignType {
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaignType {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-weight: bold;
  font-size: 1rem;
}
.campaignType span {
  position: relative;
  left: -15px;
  font-size: 0.85rem;
}

.campaignNameWrapper {
  justify-content: flex-start;
}

/* .campaignsTable .campaignNameColumn {
  padding-left: 50px;
} */

.status {
  color: #fff;
  text-transform: capitalize;
  min-width: 90px;
  position: relative;
  margin: 10px 0;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  border: 1px solid transparent;
}

.statusactive {
  background-color: #02875b1a;
  border-color: #02875b;
  color: #02875b;
}

.statuspaused {
  background-color: #d1d1d11a;
  border-color: #d1d1d1;
  color: #d1d1d1;
}

.pricingModel {
  text-transform: uppercase;
}

.statusrejected {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}

.statuspending {
  background-color: #faad141a;
  border-color: #faad14;
  color: #faad14;
}

.statusready {
  background-color: #02875b1a;
  border: 1px solid #02875b;
  color: #02875b;
}
.statusimplementing {
  background-color: #1990ff1a;
  border: 1px solid #1990ff;
  color: #1990ff;
}
.statusdisabled {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}
.statusrequested {
  background-color: #781edf1a;
  border-color: #781edf;
  color: #781edfc9;
}

.statusarchived {
  background-color: #ffc2191a;
  border: 1px solid #ffc219;
  color: #ffc219;
}

.campaignTypePushCpa {
  color: #32b66b;
}
.campaignTypePushCpc {
  color: #d95c5c;
}
.campaignTypeNativeCpc {
  color: #3067ce;
}
.campaignTypeSmartlinkCpa {
  color: #781edf;
}
.nameInitials {
  width: 2.3em;
  height: 2.3em;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(85, 85, 85);
  padding: 5px;
  margin-right: 5px;
  text-align: center;
}

.campaignsTable :global(.ant-table-tbody > tr > td) {
  padding: 10px 12px;
}

.channelName,
.companyName,
.dateCreated {
  text-align: left;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* .channelName {
  width: 200px;
} */
.campaignId {
  font-size: 0.8rem;
}
.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions > div {
  cursor: pointer;
  margin-right: 10px;
}

.actions div:last-child {
  margin-right: 0;
}

.actions :global(.ant-popover-inner-content) {
  padding: 5px 8px !important;
}

/* CAMPAIGNS TITLE AND FILTERS AREA */

.plusIconCamp {
  font-size: 25px;
  font-weight: bold;
}

.filtersContainer {
  background-color: #fafafa;
  padding: 0.5rem 1rem 1rem 1rem;
  margin-bottom: 0.6rem;
}
.dateRadioSelect {
  font-size: 0.9rem;
}

.filtersContainer
  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-radius: 5px;
}
.filtersContainer :global(.ant-picker) {
  border-radius: 5px;
}

.dateRadioSelect :global(.ant-radio-button-wrapper) {
  color: #3d8bf88f;
  font-size: inherit;
  background-color: transparent;
}
.dateRadioSelect
  :global(.ant-radio-button-wrapper.ant-radio-button-wrapper-checked) {
  color: #3d8af8;
}
.campaignsTable :global(.ant-radio-group-large .ant-radio-button-wrapper) {
  border: none;
}
.campaignsTable
  :global(.ant-radio-group-large .ant-radio-button-wrapper::before) {
  display: none;
}
.dateRadioSelect {
  margin-bottom: 0.5rem;
}

.hideShowLinkBtn {
  color: #3d8af8;
  padding: 0.5rem 0 0 0;
  font-size: 0.8rem;
}

.spanMarginBottom {
  color: rgb(95, 95, 95);
}

.isArchivedCheckbox {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 10px;
  padding-bottom: 5px;
}

.filtersContainerBottom :global(.Select) {
  width: 200px;
  /* height: 32px; */
  margin-right: 1rem;
  border-radius: 0;
}
.filtersContainerBottom :global(.Select-control) {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  height: 31px;
  z-index: 10;
  overflow: auto;
}
.filtersContainerBottom :global(.has-value .Select-control > *:last-child) {
  display: inline-block;
  margin-left: 0rem;
}

.filtersContainerBottom :global(.Select--multi .Select-clear-zone) {
  display: inline-block;
  margin-left: 5.5rem;
}

.filtersContainerBottom :global(.Select-value .Select-value-label) {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filtersContainerBottom :global(.Select-input) {
  height: 30px;
}
.filtersContainerBottom :global(.Select-placeholder) {
  line-height: 31px;
}
.filtersContainerBottom :global(.Select-value) {
  width: 100%;
  max-width: 185px;
  white-space: nowrap;
  overflow: hidden;
}
.rangeDatePicker {
  margin-right: 1rem;
}
.inputTextSearch {
  max-width: 400px;
  width: 50%;
  min-width: 150px;
  margin-right: 1rem;
}

.selectStatuses,
.selectFormats {
  height: 32px;
  width: 100%;
  margin-right: 1rem;
}

.applyFiltersBtn {
  background-color: #3d8af8;
  border-color: #3d8af8;
  margin-left: auto;
}

/* .campaignsTable .selectStatuses :global(.ant-select-selector),
.campaignsTable .selectFormats :global(.ant-select-selector) {
  height: 100%;
} */

.extActionGroup {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem;
}

.exportToCSV {
  border: none;
  box-shadow: none;
  color: #3d8af8;
}

.reloadBtn {
  color: #a3a6b4;
  margin-left: 1rem;
  border: 1px solid #bcbec6;
  border-radius: 0;
}

.columnsBtn {
  color: #464646;
  border: 1px solid rgb(196, 196, 196);
  margin-left: 0;
  border-radius: 3px;
  margin-right: 1rem;
}

.columnsBtn:last-child {
  margin-right: 0px;
}

.countryDesc {
  display: flex;
  align-items: center;
}

/* LINK MODAL */
.actionGroupLink {
  display: flex;
  justify-content: flex-end;
}

.copyLinkBtn {
  margin-top: 0.5rem;
  background-color: #f9ae3b;
  border-color: #f9ae3b;
  color: #ffffff;
  /* float: right; */
}

.bottomActions {
  margin-top: 2rem;
  display: flex;
}
.bottomActions .rightActionBtns > * {
  margin-left: 0;
}

.rightActionBtns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottomActions :global(.ant-radio-button.ant-radio-button-checked) {
  background-color: #3d8af8;
  border-color: #3d8af8;
}

@media (max-width: 1000px) {
  .filtersContainerBottom {
    flex-direction: column;
    align-items: center;
  }
  .inputTextSearch,
  .selectStatuses,
  .rangeDatePicker,
  .selectFormats {
    width: 100%;
    max-width: none;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  .applyFiltersBtn {
    max-width: 400px;
    margin: 0;
  }
  .campaignsTable :global(.ant-radio-group-large .ant-radio-button-wrapper) {
    display: none;
  }
  .extActionGroup {
    flex-direction: column;
  }
  .titleContent {
    display: flex;
    flex-direction: row;
  }
  .columnsBtn,
  .reloadBtn {
    margin-bottom: 0.4rem;
  }
  .bottomActions {
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
  }
  .rightActionBtns {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }
  .bottomActions :global(.ant-radio-group-solid) {
    display: none;
  }

  .bottomActions .rightActionBtns > * {
    margin-left: 0rem;
    margin-top: 0.5rem;
    width: 100%;
  }
}
@media (min-width: 750px) {
  .campaignsTable :global(.ant-table-footer > ul > li:first-child) {
    background-color: #fff;
    display: inline-block;
    position:absolute;
    left: 0px;
  
  }
}
.actionCampaignIcons {
  font-size: 17px;
  position: relative;
  top: 3px;
}
