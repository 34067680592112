.drawerContainer :global(.ant-drawer-body) {
  padding: 0;
  background-color: transparent;
}

.drawerContainer :global(.ant-drawer-content) {
  box-shadow: 1px 1px 20px black;
}

.moduleContent {
  height: 100%;
  width: 100%;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
}

.moduleContentList {
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 1rem 0;
}

.logoModule {
  height: 56px;
}

.moduleNameDropdown {
  font-size: 0.8rem;
  border-bottom: 1px solid rgb(248, 248, 248);
  display: flex;
  align-items: center;
  margin: 0;
  padding: 1rem 1.3rem;
  color: rgb(90, 90, 90);
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.moduleNameDropdown:last-child {
  border-bottom: 1px solid transparent;
}

.moduleNameDropdown:hover {
  color: #1890ff;
  background-color: #fafafa;
}

.moduleNameDropdown:after {
  display: block;
  content: '';
  border-bottom: solid 1px #1890ff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
}

.moduleNameDropdown:hover:after {
  transform: scaleX(1);
}

.moduleNameDropdown:hover .moduleInitials {
  border: 1px solid #1890ff;
}

.moduleCarrot {
  font-size: 0.75rem;
  font-weight: 600;
  color: #1990FF;
  margin-left: .5rem;
}

.moduleNameDropdownLocked {
  font-size: 0.85rem;
  border-bottom: 1px solid rgb(248, 248, 248);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 1rem 1.3rem;
  color: #5a5a5a;
  font-weight: 600;
  transition: all 0.2s;
  cursor: pointer;
  position: relative;
}

.moduleNameDropdownLocked .moduleInitials,
.moduleNameDropdownLocked .moduleTitle span:first-child,
.moduleNameDropdownLocked .moduleDescription {
  opacity: 0.5;
}

.moduleNameDropdownLocked:hover {
  background-color: #fafafa;
}

.moduleNameDropdownLocked:after {
  display: block;
  content: '';
  border-bottom: solid 1px #1890ff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
}

.moduleNameDropdownLocked:hover:after {
  transform: scaleX(1);
}

.moduleTitle {
  display: flex;
  align-items: center;
}

.moduleDescription {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}

.moduleInitials {
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(240, 240, 240);
  background-color: #fafafa;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 1rem;
}

.dropdownContentFix {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 .5rem 0;
}

.dropdownContentFix>span {
  font-size: 0.9rem;
  color: rgb(149, 149, 149);
  margin-bottom: 0.8rem;
}

.dropdownContentFix h2 {
  color: #5a5a5a;
  font-size: 1rem;
  font-weight: 600;
  margin: .2rem 0;
}

.dropdownContentFix>a {
  font-size: 0.8rem;
}

.btnDropdownLogout {
  color: rgb(101, 101, 101);
  font-size: 0.8rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-bottom: .8rem;
}

.btnDropdownContent {
  color: rgb(101, 101, 101);
  font-size: 0.8rem;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  margin-bottom: 1rem;
}

.profileDropdownAvatar {
  width: 105px;
  height: 105px;
  object-fit: cover;
  position: relative;
  border-radius: 50%;
  margin-bottom: 0.6rem;
}

.menuDropdownItem {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem 1rem;
  color: rgb(90, 90, 90);
  border-top: 1px solid rgb(226, 226, 226);
  transition: all 0.2s;
}

.menuDropdownItem:hover {
  opacity: 0.8;
  color: #1890ff;
}