.formContainer {
  color: #4d4f5c;
}
.pushContent {
  margin-bottom: 2rem;
}

.formContainer > h3 {
  color: #43425d;
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.7rem;
  border-bottom: 1px solid rgb(227, 227, 227);
}

.frequencyContent {
  margin-left: 1rem;
}

.frequencyBox {
  margin-top: 0.5rem;
  display: flex;
}

.frequencyBox :global(.ant-input-number) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.frequencyBox .secondFreq :global(.ant-select-selector) {
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.inputNumberDelay :global(.ant-input) {
  border-radius: 5px;
}
.inputNumberDelay :global(.ant-input-group-addon) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.inputLabel {
  font-size: 0.9rem;
  color: #4d4f5c;
  font-weight: 500;
}

.createContent {
  max-width: 900px;
  margin-left: 1rem;
}
.actionBox {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.actionBox :global(.ant-btn) {
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 5px;
}

.scriptBox {
  position: relative;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 1rem;
  padding-right: 5.35rem;
  overflow: auto;
  word-wrap: break-word;
  color: #aeaeae;
  margin-bottom: 1rem;
  width: 60%;
  max-width: 650px;
}

.copyLinkBtn {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 0px;
}

.backBtnSmartlink {
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 5px;
}

.parRule {
  color: #656770;
  margin-bottom: 1.5rem;
}

.parHints {
  font-weight: 500;
  color: #4d4f5c;
}
.parHintsOnClick {
  font-weight: 600;
  color: #1990ff;
}

.parHintItalic {
  font-style: italic;
}
.parHintItalic > span {
  background-color: #f8494933;
  color: #f84949;
  padding: 0.1rem 0.2rem;
  border-radius: 8px;
  margin-left: 0.5rem;
}

.buttonBoxPush > .swNotUploaded {
  color: #f84949;
}
.buttonBoxPush > .swUploaded {
  color: #008002;
}

.buttonBoxPush {
  display: flex;
  align-items: center;
}
.buttonBoxPush > p {
  color: #d1d1d1;
  padding-left: 0.8rem;
  margin: 0;
}
.radioStyle {
  display: block;
  height: 30px;
  line-height: 30px;
}

.settingsBox :global(.ant-form-item) {
  margin: 0;
}
.frequencyContent :global(.ant-form-item) {
  margin: 0;
}
.settingsBox > div {
  align-items: center;
  display: flex;
  margin-bottom: 0.7rem;
}
.settingsBox > div > p {
  margin: 0;
  font-size: 0.83rem;
  font-weight: 500;
  color: rgb(99, 99, 99);
  padding-left: 1rem;
  padding-right: 0.3rem;
}
.showHideExamples {
  color: #1990ff;
  text-decoration: underline;
  cursor: pointer;
}
/* settings switch styling */
/* :global(.ant-modal-content) {
  border-radius: 10px;
  overflow: hidden;
}
.settingsBox :global(.ant-switch) {
  min-width: 65px;
  height: 32px;
}
.settingsBox :global(.ant-switch-handle) {
  top: 4px;
  left: 4px;
  width: 24px;
  height: 24px;
}
.settingsBox :global(.ant-switch-checked .ant-switch-handle) {
  left: calc(100% - 28px);
}
.settingsBox :global(.ant-switch-handle::before) {
  border-radius: 50%;
} */

@media (max-width: 800px) {
  .formContainer {
    width: 100%;
  }
}
