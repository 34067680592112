.adPreviewContentBox {
  display: flex;
  flex-wrap: wrap;
  /* max-width: 500px; */
}

.adPreviewContentBox span {
  white-space: nowrap;
}
.adPreviewScreen {
  padding: 10px 15px;
  /* border: 3px solid #cccccc; */
  border-radius: 5px;
  opacity: 1;
}

/* .adPreviewBoxSelected .adPreviewScreen {
  border: 3px solid #1990ff;
  opacity: 1;
} */

.widgetStyleTitle {
  text-transform: capitalize;
  font-size: 1rem;
  color: #a2a2a2;
  margin-bottom: 8px;
}
.adPreviewBoxSelected .widgetStyleTitle {
  color: #1990ff;
}

.desktopPreviewContent .adPreviewContentBox > div {
  position: relative;
  flex-basis: 49%;
  margin-right: 1%;
  margin-bottom: 0.2rem;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.playW3 {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.playW3::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 6px;
  left: 9px;
}

.widget3Icon {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 33px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.widget4Icon {
  color: #ffff;
  background-color: #4db7a1;
  position: absolute;
  top: 50%;
  padding-left: 3px;
  transform: translate(50%, -50%);
  border: 1px solid #4db7a1;
  height: 33px;
  width: 33px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  right: 0px;
}

.widget-style-1 {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  position: relative;
  flex-basis: 49%;
  margin-right: 0.5%;
  margin-bottom: 3px;
  padding: 15px 33px 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  color: #555;
  text-decoration: none;
  background-size: 200% 100%;
  background-position: 100% 0;
  box-sizing: border-box;
  transition: 0.2s;
  font-size: 0.85rem;
}
.widget-style-2 {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 15px;
  box-sizing: border-box;
  position: relative;
  flex-basis: 49%;
  margin-right: 0.5%;
  margin-bottom: 3px;
  padding: 15px 33px 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  color: #555;
  text-decoration: none;
  box-sizing: border-box;
  font-size: 0.85rem;
}
.widget-style-3 {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  position: relative;
  flex-basis: 49%;
  margin-right: 0.5%;
  margin-bottom: 3px;
  padding: 15px 33px 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  color: #555;
  text-decoration: none;
  background-size: 200% 100%;
  background-position: 100% 0;
  box-sizing: border-box;
  transition: 0.2s;
  font-size: 0.85rem;
}

.widget-style-4 {
  display: block;
  margin-right: 0.5%;
  padding: 18px 33px 18px 20px;
  position: relative;
  box-sizing: border-box;
  transition: 0.2s;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.85rem;
  margin-bottom: 10px;
  overflow: hidden;
  border: none;
  color: #4d4f5c;
  flex-basis: 49%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  box-shadow: 0 1px 5px #ccc;
  background-color: #f5f5f4;
}

.widget-style-5 {
  display: block;
  margin-right: 0.5%;
  padding: 18px 33px 18px 20px;
  position: relative;
  box-sizing: border-box;
  transition: 0.2s;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.85rem;
  margin-bottom: 10px;
  overflow: hidden;
  border: none;
  color: #0070c9;
  flex-basis: 49%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 0 1px 5px #ccc;
}

.widget-style-6 {
  display: block;
  margin-right: 0.5%;
  padding: 18px 33px 18px 20px;
  position: relative;
  box-sizing: border-box;
  transition: 0.2s;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.85rem;
  margin-bottom: 10px;
  overflow: hidden;
  border: 1px solid #00b4be;
  color: #fff;
  background-color: #00b4be;
  flex-basis: 49%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}
.widget-style-7 {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid #cfcfcf;
  border-radius: 50px;
  box-sizing: border-box;
  position: relative;
  margin: right 1.5%;
  margin-bottom: 3px;
  padding: 15px 33px 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  color: #555;
  text-decoration: none;
  background-size: 200% 100%;
  background-position: 100% 0;
  box-sizing: border-box;
  transition: 0.2s;
  font-size: 0.85rem;
}

.adPreviewScreen > .adPreviewContentBox > .widget-style-7:nth-child(odd) {
  margin-right: 3%;
}

.widget-style-8 {
  display: flex;
  flex-grow: 1;
  height: 44px;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid #cfcfcf;
  border-right: 0;
  margin-right: 0;
  box-sizing: border-box;
  position: relative;
  /* margin-bottom: 3px; */
  padding: 15px 0px 15px 15px;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  color: #555;
  text-decoration: none;
  background-size: 200% 100%;
  background-position: 100% 0;
  box-sizing: border-box;
  transition: 0.2s;
  font-size: 0.85rem;
}

.widget-style-9 {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid #4db7a1;
  border-radius: 5px;
  color: #555;
  box-sizing: border-box;
  position: relative;
  flex-basis: 49%;
  margin-right: 3%;
  margin-bottom: 10px;
  padding: 20px 33px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  transition: 0.2s;
  font-size: 0.85rem;
}

.widget-style-10 {
  display: block;
  margin-right: 0.5%;
  padding: 18px 33px 18px 20px;
  position: relative;
  box-sizing: border-box;
  transition: 0.2s;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.85rem;
  margin-bottom: 10px;
  overflow: hidden;
  border: none;
  color: #ffff;
  flex-basis: 49%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  box-shadow: 0 1px 5px #ccc;
  background-color: rgb(158, 198, 159);
}

.widget-style-11 {
  border: 1px solid #cfcfcf;
  font-family: Arial, Helvetica, sans-serif;
  background-size: 200% 100%;
  background-position: 100% 0;
  position: relative;
  color: #555;
  text-decoration: none;
  transition: 0.2s;
  box-sizing: border-box;
  font-size: 0.85rem;
  overflow: hidden;
  border-radius: 4px;
  display: block;
  padding: 15px 33px 15px 15px;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.styledDivLeft,
.styledDivRight {
  width: 4px;
  height: 35px;
}

.styledDivRight {
  display: block;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.styledDivLeft {
  display: block;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.outerDiv {
  display: flex;
  flex-basis: 49%;
}

.triangleRight {
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 22px solid #cfcfcf;
  position: relative;
}
.triangleRight::after {
  content: " ";
  width: 0;
  height: 0;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
  border-left: 21px solid white;
  position: absolute;
  top: -21px;
  right: 1px;
}

.arrowWrapper {
  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  padding-right: 5px;
  display: flex;
  align-items: center;
  font-family: system-ui;
}
.arrowWrapper > i {
  margin-left: 10px;
}
/* WIDGET STYLE 12 */
.adPreviewContentBox > .widget-style-12 {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
}
.adPreviewContentBox > .widget-style-12 .widgetIcon12 {
  width: 40px;
}
.adPreviewContentBox > .widget-style-12:nth-child(1) {
  background-color: rgb(215, 103, 71);
}
.adPreviewContentBox > .widget-style-12:nth-child(1) .widgetIcon12 {
  background-color: #fff;
  color: rgb(215, 103, 71);
  opacity: 1;
}

.adPreviewContentBox > .widget-style-12:nth-child(2) {
  background-color: rgb(215, 149, 71);
}
.adPreviewContentBox > .widget-style-12:nth-child(2) .widgetIcon12 {
  background-color: #fff;
  color: rgb(215, 149, 71);
  opacity: 1;
}

.adPreviewContentBox > .widget-style-12:nth-child(3) {
  background-color: rgb(13, 124, 66);
}
.adPreviewContentBox > .widget-style-12:nth-child(3) .widgetIcon12 {
  background-color: #fff;
  color: rgb(13, 124, 66);
  opacity: 1;
}

.adPreviewContentBox > .widget-style-12:nth-child(4) {
  background-color: rgb(67, 142, 183);
}
.adPreviewContentBox > .widget-style-12:nth-child(4) .widgetIcon12 {
  background-color: #fff;
  color: rgb(67, 142, 183);
  opacity: 1;
}

/* @media (max-width: 1465px) {
  .adPreviewBox:nth-child(even) {
    flex-basis: 100%;
    margin-left: 0px;
  }
  .adPreviewBox:nth-child(odd) {
    flex-basis: 100%;
    margin-left: 0px;
  }
} */
