.headInfoContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.7rem;
}

/* HEAD INFO CARD */
.infoCard {
  box-shadow: 0px 2px 6px #00000012;
  padding: 0.9rem;
}
.infoCard * {
  margin: 0;
  color: #a3a6b4;
}
.infoCard > p {
  font-size: 1.2rem;
  padding-bottom: 0.7rem;
}
.cardValues p:first-child {
  font-size: 1.6rem;
  font-weight: bold;
}
.cardValues p:last-child {
  line-height: 1rem;
}

/* DASHBOARD TOP CARDS */

.dashboardBox {
  background: #fff;
  margin-bottom: 0px;
  overflow: hidden;
  position: relative;
  /* height: 118px; */
}
.col-graph {
  opacity: 0;
  cursor: pointer;
  width: 100%;
  margin: 1px;
  position: relative;
  text-align: center;
}
.col-graph:hover {
  opacity: 0.8;
}
.col-graph0 {
  animation-delay: 0s;
  animation-duration: 0.8s;
  animation-name: slidein;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.col-graph1 {
  animation-delay: 0.2s;
  animation-duration: 0.8s;
  animation-name: slidein;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.col-graph2 {
  animation-delay: 0.4s;
  animation-duration: 0.8s;
  animation-name: slidein;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.col-graph3 {
  animation-delay: 0.6s;
  animation-duration: 0.8s;
  animation-name: slidein;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.col-graph4 {
  animation-delay: 0.8s;
  animation-duration: 0.8s;
  animation-name: slidein;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.col-graph5 {
  animation-delay: 1s;
  animation-duration: 0.8s;
  animation-name: slidein;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.col-graph6 {
  animation-delay: 1.2s;
  animation-duration: 0.8s;
  animation-name: slidein;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.col-graph7 {
  animation-delay: 1.4s;
  animation-duration: 0.8s;
  animation-name: slidein;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.col-graph8 {
  animation-delay: 1.6s;
  animation-duration: 0.8s;
  animation-name: slidein;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.col-graph9 {
  animation-delay: 1.8s;
  animation-duration: 0.8s;
  animation-name: slidein;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.col-graph10 {
  animation-delay: 2s;
  animation-duration: 0.8s;
  animation-name: slidein;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.headerStatsBox {
  display: flex;
  justify-content: space-around;
  position: relative;
  bottom: -4px;
  height: 86px;
}
.mainDiv {
  display: -ms-flexbox;
  display: flex;
  flex: 2;
  -ms-flex-align: end;
  align-items: flex-end;
  justify-content: flex-end;
}
.dashboardBox p {
  font-size: 1.2rem;
  position: relative;
  text-align: left;
  padding-left: 10px;
  align-self: center;
  flex: 1.5;
  color: #8a8a8a;
}

.headerStatsBox p {
  font-size: 1.3rem;
  position: relative;
  text-align: left;
  padding: 0 10px 15px 10px;
  align-self: center;
  flex: 1.5;
  font-weight: 700;
}

@keyframes slidein {
  from {
    bottom: -100%;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@media (max-width: 1300px) {
  .headInfoContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .infoCard {
    padding: 0.3rem;
  }
}

@media (max-width: 1000px) {
}
