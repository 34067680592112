.mainContainer > h4 {
  font-size: 1.4rem;
  margin-bottom: 7px;
  /* border-bottom: 1px solid #ccc; */
  color: #43425d;
}

.dashBottomContent {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

.statsDivider {
  margin-top: 1rem;
  height: 1.5rem;
  width: calc(100% + 48px);
  transform: translateX(-24px);
  background-color: #f0f2f5;
}

/* CONTACT INFO */
.contactInfoSpin {
  grid-column: span 2;
}

.statsTitle {
  color: #626c8a;
  font-size: 1.2rem;
  font-weight: 500;
  padding-top: 1rem;
  margin-bottom: 0.7rem;
}

.statsInfo {
  color: #8a8a8a;
}

.statsTitle > span {
  color: #adadb8;
  font-size: 0.9rem;
  font-weight: 400;
}

.contactInfoContainer {
  position: relative;
  padding: 0.7rem;
  display: flex;
  background-color: #465161;
}
.contactInfoLeft {
  display: flex;
  align-items: center;
}
.contactIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
}
.contactIcon:hover {
  opacity: 1;
}

.contactInfoLeft > div {
  height: 100%;
  display: flex;
  align-items: flex-end;
  width: 60px;
  justify-content: space-between;
  margin-right: 1rem;
  padding-bottom: 1rem;
}
.contactInfoLeft > img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 1rem;
  padding: 1rem;
}

.contactInfoRight {
  color: #fff;
  padding: 1rem;
  font-size: 0.9rem;
}
.contactInfoRight > div {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #fff;
}

.contactInfoRight > div :global(span) {
  margin-left: 1rem;
}
.contactInfoRight > div :global(span):last-child {
  margin-left: 0.2rem;
}
.contactInfoRight > p:first-child {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0;
  padding-bottom: 0.2rem;
}
.closeBtn {
  position: absolute;
  color: #fff;
  padding-top: 0.3rem;
  padding-right: 0.5rem;
  font-size: 1.2rem;
  top: 0;
  right: 0;
  cursor: pointer;
}
.closeMessage {
  margin: 0;
  color: #ffffffde;
  margin: 0 auto;
  text-decoration: underline;
  cursor: pointer;
}

/* EARNINGS MAP */
.earningsMapContainer {
  grid-row: span 3;
}
.earningsMapContainer .earningsMapHeader {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.1rem;
  color: #4d4f5c;
}

.earningsMapContainer :global(.jvectormap-zoomout) {
  background-color: #3234408c;
  width: 17px;
  height: 17px;
}
.earningsMapContainer :global(.jvectormap-zoomin) {
  background-color: #3234408c;
  width: 17px;
  height: 17px;
}

.mapListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mapListItem > p {
  display: flex;
  align-items: center;
  margin: 0;
}
.mapListItem > p > span {
  margin-right: 0.5rem;
}

.mapEarningsList {
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

/* CHANNELS TABLE CONTAINER */
.channelsTableContainer {
  grid-column: span 2;
  grid-row: span 3;
}

.channelsTableContainer :global(.ant-table-thead tr th) {
  color: #a3a6b4;
  font-weight: bold;
}
.channelsTableContainer > h4 {
  color: #626c8a;
  font-size: 1.2rem;
  padding: 1rem 0 0.5rem 0;
}

/* INSIGHTS */
.insightsContainer {
  border: 1px solid #dedede;
  padding: 1rem;
}
.insightsContainer > h4 {
  color: #2d71b6;
  font-weight: bold;
  font-size: 1.2rem;
}
.insightsList > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #333333;
  font-size: 0.9rem;
}
.insightsDot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f9ae3b;
  margin-right: 0.5rem;
}

/* USERS BY DEVICE PIE CHART */
.usersDevicePieContainer {
  margin-top: 1rem;
  grid-column: span 1;
}
.usersDevicePieContainer h4 {
  font-size: 1.1rem;
  color: #4d4f5c;
  margin: 0;
}
.bottomDevicesStats {
  display: flex;
  justify-content: space-around;
}
.bottomDevicesStats > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottomDevicesStats > div > p {
  margin: 0;
  padding: 0.2rem 0 0 0;
  font-size: 0.8rem;
  color: #a6a6a6;
}
.bottomDevicesStats > div > span {
  font-size: 1.1rem;
  font-weight: 600;
}

/*  NEWS BLOG */
.newsBlogContainer {
  grid-column: span 2;
  grid-row: span 2;
}
.blogCard {
  display: flex;
  cursor: pointer;
  margin-top: 1rem;
  padding-right: 1rem;
  box-shadow: 0px 2px 6px #00000012;
}
.blogCard > div:last-child {
  width: 75%;
}
.blogCard > div:first-child {
  width: 25%;
  min-width: 150px;
  max-width: 190px;
}
.blogCard:hover {
  opacity: 0.85;
  transition: opacity 0.2s;
}
.blogCard p {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.blogDesc {
  font-size: 0.8rem;
}
.blogTitle {
  font-weight: 600;
  font-size: 0.9rem;
  padding: 0.5rem;
}
.blogCard img {
  width: 100%;
  padding: 0.5rem;
}

/* ACTIVITY LOG */

.activityLogContainer {
  margin-top: 2rem;
  grid-row: span 1;
}

.activityLogContainer h4 {
  color: #303447;
  font-size: 0.9rem;
  font-weight: 600;
}
.singleActivity {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.singleActivity > div > img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}
.singleActivity p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.singleActivity p:first-child {
  font-size: 0.9rem;
}
.singleActivity p:first-child span {
  color: #9620ff;
  font-weight: 600;
}
.singleActivity p:last-child {
  font-weight: 600;
  font-size: 0.85rem;
}

.customLoaderContainer {
  margin: 100px auto;
  width: 300px;
  height: 300px;
  animation: spin 3s linear infinite;
}
.customLoaderContainer > img {
  top: 0;
  left: 0;
  transform: rotate(90deg);
  height: 70px;
}

/* REQEUST FEED */

.requestFeedRadioGroup {
  display: flex;
  margin-bottom: 20px;
}

.requestFeedContainer {
  min-height: 572px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.requestFeedRadioBox p {
  text-align: center;
  margin-bottom: 0;
}
.requestFeedRadioBox {
  text-align: center;
  margin-right: 0.7rem;
  padding: 5px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 20px;
  cursor: pointer;
  color: rgb(145, 145, 145);
  min-width: 90px;
}
.requestFeedRadioBoxSelected {
  border: 1px solid #1890ff;
  background-color: #1890ff;
  color: #fff;
}
.requestFeedRadioBox > span:first-child {
  font-weight: bold;
}

.accessFeedContent > p {
  margin-bottom: 0px;
}
.accessFeedContent > p:first-child {
  color: rgb(92, 92, 92);
  font-weight: bold;
}
.accessFeedContent > p:last-child {
  color: rgb(123, 123, 123);
  font-size: 0.85rem;
}

.buttonRequestFeed {
  font-size: 0.9rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 2rem;
  background-color: #1990fa;
}

.dropdownReqFeed {
  margin-left: 1rem;
  width: 160px;
}

.formFeedContainer :global(.ant-checkbox-group-item) {
  margin: 0.4rem;
  margin-right: 0.7rem;
  /* display: block; */
}
.feedFormTitle {
  color: #686868;
  font-size: 0.85rem;
}

.formFeedContainer :global(.ant-radio-group) {
  margin: 0.5rem;
}
.formFeedContainer :global(.ant-radio-group .ant-radio-wrapper) {
  width: 80px;
  font-size: 0.8rem;
}
.plusIconCamp {
  font-size: 25px;
  font-weight: bold;
}

.filtersContainer {
  background-color: #fafafa;
  padding: 0.5rem 1rem 1rem 1rem;
  margin-bottom: 0.6rem;
}
.dateRadioSelect {
  font-size: 0.9rem;
}

.filtersContainer
  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-radius: 5px;
}
.filtersContainer :global(.ant-picker) {
  border-radius: 5px;
}

.dateRadioSelect :global(.ant-radio-button-wrapper) {
  color: #3d8bf88f;
  font-size: inherit;
  background-color: transparent;
}
.dateRadioSelect
  :global(.ant-radio-button-wrapper.ant-radio-button-wrapper-checked) {
  color: #3d8af8;
}
.campaignsTable :global(.ant-radio-group-large .ant-radio-button-wrapper) {
  color: #2f6fb6;
  font-size: inherit;
  background-color: transparent;
}
.campaignsTable
  :global(.ant-radio-group-large .ant-radio-button-wrapper-checked) {
  font-weight: 550;
  border: 1px solid !important;
  border-color: rgba(47, 111, 182, 0.4) !important;
  border-radius: 6px;
  background-color: rgba(47, 111, 182, 0.03);
  line-height: 28px;
  height: 30px;
}
.campaignsTable :global(.ant-radio-group-large .ant-radio-button-wrapper) {
  border: none;
}
.campaignsTable
  :global(.ant-radio-group-large .ant-radio-button-wrapper::before) {
  display: none;
}
.dateRadioSelect {
  margin-bottom: 0.5rem;
}

.hideShowLinkBtn {
  color: #3d8af8;
  padding: 0.5rem 0 0 0;
  font-size: 0.8rem;
}

.spanMarginBottom {
  display: inline-block;
  color: rgb(95, 95, 95);
  margin-bottom: 8px;
}

.filtersContainerBottom :global(.Select) {
  width: 200px;
  /* height: 32px; */
  margin-right: 1rem;
  border-radius: 0;
}
.filtersContainerBottom :global(.Select-control) {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  height: 31px;
  z-index: 10;
  overflow: auto;
}
.filtersContainerBottom :global(.has-value .Select-control > *:last-child) {
  display: inline-block;
  margin-left: 0rem;
}

.filtersContainerBottom :global(.Select--multi .Select-clear-zone) {
  display: inline-block;
  margin-left: 5.5rem;
}

.filtersContainerBottom :global(.Select-value .Select-value-label) {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filtersContainerBottom :global(.Select-input) {
  height: 30px;
}
.filtersContainerBottom :global(.Select-placeholder) {
  line-height: 31px;
}
.filtersContainerBottom :global(.Select-value) {
  width: 100%;
  max-width: 185px;
  white-space: nowrap;
  overflow: hidden;
}
.rangeDatePicker {
  margin-right: 1rem;
}
.inputTextSearch {
  max-width: 400px;
  width: 50%;
  min-width: 150px;
  margin-right: 1rem;
}

.selectStatuses,
.selectFormats {
  height: 32px;
  width: 100%;
  margin-right: 1rem;
}

.applyFiltersBtn {
  background-color: #3d8af8;
  border-color: #3d8af8;
  margin-left: auto;
}

/* .campaignsTable .selectStatuses :global(.ant-select-selector),
.campaignsTable .selectFormats :global(.ant-select-selector) {
  height: 100%;
} */

.extActionGroup {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem;
}

.exportToCSV {
  border: none;
  box-shadow: none;
  color: #3d8af8;
}

.columnsBtn,
.reloadBtn {
  color: #a3a6b4;
  margin-left: 1rem;
  border: 1px solid #bcbec6;
  border-radius: 0;
}

.countryDesc {
  display: flex;
  align-items: center;
}

.graphCheckboxMetrics {
  display: flex;
  flex-wrap: wrap;
}

.graphSingleMetric {
  margin-right: 10px;
  color: #adadad;
  border: 1px solid #c5c5c5;
  border-radius: 40px;
  height: 32px;
  line-height: 32px;
  padding: 0 15px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  margin-bottom: 10px;
}

.graphSelectedMetric {
  color: #1890ff;
  border: 1px solid #1890ff;
}

.filterMetricLabel {
  margin-bottom: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: scale(1.05) rotate(72deg);
  }
  40% {
    transform: scale(0.95) rotate(144deg);
  }
  60% {
    transform: scale(1.05) rotate(216deg);
  }
  80% {
    transform: scale(0.95) rotate(288deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1300px) {
  .channelsTableContainer {
    grid-column: span 3;
  }
  .insightsContainer {
    grid-column: span 3;
  }
  .usersDevicePieContainer {
    grid-row: span 2;
  }
  .contactInfoLeft {
    flex-direction: column;
  }
  .contactInfoRight > div > span {
    display: none;
  }
}
@media (max-width: 1000px) {
  .insightsContainer {
    grid-column: span 3;
  }
  .usersDevicePieContainer {
    grid-column: span 3;
  }
  .earningsMapContainer {
    grid-column: span 3;
  }
  .newsBlogContainer {
    grid-column: span 3;
  }
  .contactInfoSpin {
    grid-column: span 3;
  }
  .activityLogContainer {
    grid-column: span 3;
  }
}
