.mainContainer>h4 {
  color: #43425d;
  font-size: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgb(227, 227, 227);
}

.datePicker {
  margin-bottom: 1rem;
  width: 280px;
}

.datePickerRow {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.datePickerRow .italicText {
  font-style: italic;
  color: #1990ff;
}

.modulesWrapper :global(.ant-radio-button-wrapper) {
  width: 280px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #f0f0f0;
  background-color: #FAFAFA;
  border-radius: 3px;
  text-align: center;
  font-size: .95rem;
  color: #4D4F5C;
  padding: 15px;
  height: 70px;
}

.modulesWrapper :global(.ant-radio-button-wrapper:not(:first-child):before) {
  height: 0;
  width: 0;
  top: 0;
  left: 0;
}

.modulesWrapper :global(.ant-radio-button-wrapper-checked) {
  border: 1px solid #1890ff;
}

.modulesWrapper :global(.ant-radio-button-wrapper-checked span) {
  color: #1890ff;
}

.paymentMethodsTitle {
  font-weight: 600;
}

.payoneerContainerReg {
  position: absolute;
  bottom: 0px;
}

.payoneerInfoMessage {
  white-space: nowrap;
  font-size: 0.65rem;
  color: rgb(124, 124, 124);
  margin: 0;
  font-style: italic;
}

.payoneerLinkBtn {
  width: 175px;
}

.payoneerRegistrationBtn {
  background: #ff4700;
  border-color: #ff4700;
  color: #fff;
  width: 175px;
}

.newPaymentMethodMain {
  position: relative;
  /* display: flex; */
  flex-direction: column;
  /* align-items: center; */
}

.methodsGrid {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
}

.paymentMethodContainer {
  min-height: 146px;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgb(227, 227, 227);
  font-size: 0.8rem;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0.7rem 0.7rem 0.7rem 1.3rem;
  width: 45%;
  border-radius: 3px;
}

.paymentMethodContainer :global(.ant-spin-container) {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.paymentMethodContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.priceEditBtns {
  display: flex;
  justify-content: space-between;
}

.editPriceBtn {
  margin-left: 0.6rem;
  margin-right: 1rem;
}

.paymentMethodActions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.statusDefault {
  font-size: 0.65rem;
  font-weight: 600;
  border-radius: 3px;
  background-color: #1990ff33;
  border: 1px solid #1990ff;
  color: #1990ff;
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
}

.statusVerified {
  font-size: 0.65rem;
  font-weight: 600;
  border-radius: 3px;
  background-color: #02875b33;
  border: 1px solid #02875b;
  color: #02875b;
  padding: 0.2rem 0.5rem;
}

.statusPending {
  font-size: 0.65rem;
  font-weight: 600;
  border-radius: 3px;
  background-color: #ffc06a33;
  border: 1px solid #ffc06a;
  color: #ffc06a;
  padding: 0.2rem 0.5rem;
}

.defaultDisabled {
  font-size: 0.75rem;
  pointer-events: none;
  text-decoration: underline;
  color: rgb(208, 208, 208);
}

.defaultEnabled {
  font-size: 0.75rem;
  cursor: pointer;
  text-decoration: underline;
  color: #1990ff;
}

.defaultEnabled:hover {
  opacity: 0.7;
}

.closeBtn {
  cursor: pointer;
  font-size: 0.7rem;
  color: rgb(169, 169, 169);
}

.closeBtn:hover {
  opacity: 0.7;
}

.methodHighlight {
  text-transform: capitalize;
  font-weight: 600;
}

.methodContentBox {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.methodContentBoxForm {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.methodContentBoxForm>div {
  display: flex;
}

.tooltipOverlay {
  font-size: 0.7rem;
}

.minimumPayoutField {
  border-radius: 3px;
  width: 80px;
  outline: none;
  border: 1px solid rgb(191, 191, 191);
}

.paymentMethodBtn {
  margin: 1rem 0;
  border-radius: 3px;
  background-color: #1990ff;
}

.methodTypeContainer {
  display: flex;
  overflow: hidden;
  transition: all 0.4s;
}

.methodTypeSelect {
  cursor: pointer;
  width: 270px;
  height: 60px;
  border-radius: 3px;
  padding: 0.2rem 0.6rem;
  border: 1px solid #eeeeee;
  background-color: #fafafa;
  transition: all 0.2s;
  z-index: 10;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.methodTypeSelected {
  cursor: pointer;
  width: 270px;
  height: 60px;
  border-radius: 3px;
  padding: 0.2rem 0.6rem;
  border: 2px solid #8fc9ff;
  background-color: #fafafa;
  transition: all 0.2s;
  z-index: 10;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.methodTypeSelect:hover {
  /* background-color: #c8e5ff; */
  border: 1px solid #8fc9ff;
}

.uploadInvoiceFormContainer {
  /* height: 100%; */
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.uploadInvoiceFormContainer :global(.ant-upload-list-item-card-actions-btn) {
  opacity: 1;
}

.uploadInvoiceFormContainer :global(.ant-upload-list-item) {
  max-width: 100%;
  display: inline-block;
}

.highlightText {
  color: rgb(104, 104, 104);
  font-size: 0.85rem;
  font-weight: 600;
}

.dualFieldItem {
  display: flex;
}

.dualFieldItem :global(.ant-input),
:global(.ant-form-item textarea.ant-input),
.dualFieldItem :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-radius: 3px;
}

.dualFieldItem :global(.ant-form-item-label > label),
:global(.ant-form-vertical .ant-form-item-label > label) {
  color: rgb(104, 104, 104);
  font-size: 0.85rem;
}

:global(.ant-drawer-title) {
  color: rgb(84, 84, 84);
  font-weight: 600;
  font-size: 1rem;
}

.dualFieldItem :global(.ant-form-item-label > label.ant-form-item-required::before) {
  color: rgb(104, 104, 104);
}

.dualFieldItem :global(.ant-form-item) {
  width: 48%;
}

.dualFieldItem :global(.ant-form-item):first-child {
  margin-right: 4%;
}

@media only screen and (max-width: 600px) {
  .datePicker {
    width: 100%;
  }

  .modulesWrapper :global(.ant-radio-button-wrapper) {
    width: 100%;
    height: auto;
    line-height: 1.5;
  }
}