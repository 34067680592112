@font-face {
  font-family: "Menlo Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Menlo Regular"),
    url("../../fonts/Menlo-Regular.woff") format("woff");
}
.mainContainer {
  color: #4d4f5c;
}
.mainContainer > h4 {
  color: #43425d;
  font-size: 1.5rem;
  padding-bottom: 1rem;
}
.mainContainer > div {
  margin-bottom: 40px;
}
.firstColumn {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
}
.firstColumn img {
  width: 50px;
}
.secondColumn h2 {
  font-size: 16px;
  line-height: 0.8;
  margin-bottom: 20px;
}
.secondColumn p {
  font-size: 16px;
  letter-spacing: 0px;
  margin-bottom: 0;
}
.tableContainer {
  margin-top: 20px;
}
.tableContainer :global(table) {
  font-size: 16px;
}
.tableContainer :global(table th),
.tableContainer :global(table td) {
  vertical-align: center;
}
.tableContainer :global(table ul) {
  margin: 0;
  padding: 0;
  list-style: none;
}
.requestContainer {
  margin-top: 20px;
  text-align: left;
  max-width: 800px;
}
.requestContainer p {
  padding: 0 5px;
  word-break: break-all;
}
.requestContainer span {
  padding: 2px 0;
  color: #448ef7;
  background-color: #e8f4ff;
  line-height: 1;
  letter-spacing: 0.2px;
}
.apiKeyStyle {
  font-size: 15px;
  font-family: "Menlo Regular";
  border-radius: 2px;
  border: 1px solid #d1d1d1;
  background: #d1d1d11a;
  padding: 2px 5px;
}
.apiKeyBold {
  font-size: 15px;
  font-family: "Menlo Regular";
  font-weight: 600;
}
.responseContainer {
  margin-top: 20px;
  background-color: #f0f0f0;
  padding: 30px 50px;
  position: relative;
  max-width: 800px;
}
.responseContent {
  margin-top: 30px;
  position: relative;
  height: 400px;
  overflow-y: scroll;
}
.responseContent::-webkit-scrollbar {
  width: 6px;
}
.responseContent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
}
.responseContent::-webkit-scrollbar-thumb {
  background: #b4b4b4;
  height: 30px;
  border-radius: 6px;
}
.copyButton span {
  margin-right: 8px;
}
.copyButton {
  position: sticky;
  top: 0;
  left: 100%;
  background-color: #1890ff;
  color: #fff;
  font-size: 16px;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 6px;
}
.copyButton:hover {
  cursor: pointer;
}
.responseContainer :global(pre) {
  font-family: "Menlo Regular";
  margin-bottom: 0;
}

.paginationPreview {
  border: 1px solid #f0f0f0;
  padding: 20px 30px;
  border-radius: 10px;
}
.paginationPreview > p {
  font-weight: 500;
  margin-bottom: 8px;
}
.paginationPreview > div {
  display: flex;
  gap: 20px;
}
.paginationPreview > div p {
  margin-bottom: 8px;
}
.paginationPreview .videoPreviewContainer {
  border: 1px solid #f0f0f0;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  transition: opacity 0.3s;
}
.paginationPreview .videoPreviewContainer:hover {
  opacity: 0.7;
}
.paginationPreview .videoPreviewContainer img {
  height: 240px;
  width: 420px;
  object-fit: cover;
}

@media only screen and (max-width: 1240px) {
  .paginationPreview > div {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1060px) {
  .firstColumn {
    display: none;
  }
  .firstColumn img {
    display: none;
  }
  .secondColumn h2 {
    line-height: 1.3;
  }
  .responseContainer {
    padding: 30px 30px;
  }
}
