.inpageContainer {
  display: flex;
}

.formContainer {
  width: 100%;
  /* max-width: 650px; */
  color: #4d4f5c;
}

.pushContent {
  margin-bottom: 2rem;
}

.formContainer>h3 {
  color: #43425d;
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.7rem;
  border-bottom: 1px solid rgb(227, 227, 227);
}

.formContainer>h3 span {
  color: #4d4f5c;
  font-size: 1.3rem;
}

.frequencyContent {
  margin-left: 1rem;
}

.frequencyBox {
  margin-top: 0.5rem;
  display: flex;
}

.frequencyBox :global(.ant-input-number) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.frequencyBox .secondFreq :global(.ant-select-selector) {
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.inputNumberDelay :global(.ant-input) {
  border-radius: 5px;
}

.inputNumberDelay :global(.ant-input-group-addon) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.inputLabel {
  font-size: 0.9rem;
  color: #4d4f5c;
  font-weight: 500;
}

.infoLabel {
  font-size: 0.85rem;
  color: #4d4f5c;
  /* font-weight: 500; */
}

.middleCustomBox {
  margin-bottom: 1rem;
}

.adPlacementBox {
  padding-left: 1rem;
  margin-bottom: 1.5rem;
}

.colorBox {
  width: 35px;
  height: 35px;
  border: 2px solid rgb(226, 226, 226);
  border-radius: 8px;
}

.createContent {
  margin-left: 1rem;
  flex: 1;
}

/* :global(.ant-popover-inner-content) {
  padding: 0px 0px;
} */

.inpageBox {
  flex: 1;
}

.inpageBox:first-child {
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 2px solid rgb(226, 226, 226);
}

.inpageBox:nth-child(2) {
  margin-right: 1rem;
}

.colorPreviewRow {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.colorPreviewRow>p {
  color: #8a8a8a;
  padding-left: 1rem;
  padding-right: 0.7rem;
  /* width: 200px; */
  margin: 0;
}

.undoButton {
  font-size: 1.5rem;
  color: #8a8a8a;
  margin-left: 0.6rem;
  cursor: pointer;
}

.borderRadiusBox {
  display: flex;
}

.actionBox {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.actionBox :global(.ant-btn) {
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 5px;
}

.scriptBox {
  width: 900px;
  position: relative;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 1rem;
  padding-right: 5.35rem;
  overflow: auto;
  word-wrap: break-word;
  color: #aeaeae;
  margin-bottom: 1rem;
}

.copyLinkBtn {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 0px;
}

.tutorialContainer {
  width: 900px;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tutorialContainer p:last-child {
  margin: 0;
}

.tutorialContainer .videoPreview {
  cursor: pointer;
  width: 260px;
  margin-right: 30px;
}

.tutorialContainer .videoInstructions span {
  cursor: pointer;
  color: #1990FF;
  text-decoration: underline;
}

.parRule {
  margin: 26px 0;
  color: #656770;
}

.parHints {
  font-weight: 600;
  color: #1990ff;
}

.parHintItalic {
  font-style: italic;
}

.parHintItalic>span {
  background-color: #f8494933;
  color: #f84949;
  padding: 0.1rem 0.2rem;
  border-radius: 8px;
  margin-left: 0.5rem;
}

.buttonBoxPush>.swNotUploaded {
  color: #f84949;
}

.buttonBoxPush>.swUploaded {
  color: #008002;
}

.buttonBoxPush {
  display: flex;
  align-items: center;
}

.buttonBoxPush>p {
  color: #d1d1d1;
  padding-left: 0.8rem;
  margin: 0;
}

.radioStyle {
  display: block;
  height: 30px;
  line-height: 30px;
}

.settingsBox :global(.ant-form-item) {
  margin: 0;
}

.frequencyContent :global(.ant-form-item) {
  margin: 0;
}

.settingsBox>div {
  align-items: center;
  display: flex;
  margin-bottom: 0.7rem;
}

.settingsBox>div>p {
  margin: 0;
  font-size: 0.83rem;
  font-weight: 500;
  color: rgb(99, 99, 99);
  padding-left: 1rem;
  padding-right: 0.3rem;
}

/* settings switch styling */
/* :global(.ant-modal-content) {
    border-radius: 10px;
    overflow: hidden;
  }
  .settingsBox :global(.ant-switch) {
    min-width: 65px;
    height: 32px;
  }
  .settingsBox :global(.ant-switch-handle) {
    top: 4px;
    left: 4px;
    width: 24px;
    height: 24px;
  }
  .settingsBox :global(.ant-switch-checked .ant-switch-handle) {
    left: calc(100% - 28px);
  }
  .settingsBox :global(.ant-switch-handle::before) {
    border-radius: 50%;
  } */

/* PREVIEW BOX CONTAINER */
.adPreviewBox {
  border: 2px solid rgb(226, 226, 226);
  margin-left: 1rem;
  border-radius: 0.5rem;
}

.adPreviewTitle {
  font-size: 1rem;
  padding: 1rem;
  border-bottom: 2px solid rgb(226, 226, 226);
  background-color: #fafafa;
}

.adPreviewTitle>p {
  margin: 0;
}

.adPreviewWindow {
  background-color: rgb(250, 250, 250);
  position: relative;
  padding: 0.7rem;
  height: 450px;
}

.adPreviewWindow>div:nth-child(2) div {
  top: 9rem !important;
}

.showHideExamples {
  color: #1990ff;
  text-decoration: underline;
  cursor: pointer;
}

.backBtnInPage {
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 5px;
}

/* :global(.ant-radio-inner::after) {
  top: 50%;
  left: 50%;
}
:global(.ant-radio-checked .ant-radio-inner::after) {
  transform: scale(1) translate(-50%, -50%);
} */

@media (max-width: 1400px) {
  .inpageContainer {
    flex-direction: column;
  }

  .inpageBox:first-child {
    margin-right: 0rem;
    border-right: 0px;
  }

  .adPreviewBox {
    margin-left: 0rem;
  }
}

@media (max-width: 800px) {
  .formContainer {
    width: 100%;
  }
}

@media (max-width: 1200px) {

  .scriptBox,
  .tutorialContainer {
    width: 100%;
  }
}

@media (max-width: 965px) {
  .tutorialContainer {
    flex-direction: column;
  }

  .tutorialContainer .videoPreview {
    width: 260px;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .tutorialContainer .videoInstructions {
    text-align: center;
  }
}